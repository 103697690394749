import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setAvoidSearchLimit,
  setUploadFileName,
} from "../../app/reducers/UploaderSlice";

import styles from "./Upload.module.css";

import PastSearchImages from "../../components/pastSearchImages/PastSearchImages";

import UploadSelector from "../../components/uploadSelector/UploadSelector";
import Uploader from "../../components/uploader/Uploader";

const Upload = () => {
  const dispatch = useDispatch();

  const [viewState, setViewState] = useState("select");

  useEffect(() => {
    dispatch(setAvoidSearchLimit(false));
    dispatch(setUploadFileName(""));
  }, []);

  if (viewState === "select") {
    return <UploadSelector setViewState={setViewState}></UploadSelector>;
  }

  if (viewState === "upload") {
    return <Uploader goBack={() => setViewState("select")}></Uploader>;
  }

  if (viewState === "past") {
    return (
      <>
        <div className={styles.uploaderWrapper}>
          <PastSearchImages
            goBack={() => setViewState("select")}
          ></PastSearchImages>
        </div>
      </>
    );
  }
};

export default Upload;
