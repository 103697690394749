import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6Ld9jfAgAAAAALAx-BS9CF7LEC0gQ2i8OGKl_v9U";

const Recaptcha = ({ onCompleteEvent }) => {
  const onCaptchaChange = (event) => {
    // TODO call api to double check token
    if (event) {
      setTimeout(() => {
        onCompleteEvent();
      }, 1500);
    }
  };

  return <ReCAPTCHA sitekey={SITE_KEY} onChange={onCaptchaChange} />;
};

export default Recaptcha;
