import React from "react";

export const formatVerifiedStudents = (studentObjArray) => {
  return (studentObjArray || []).map((student) => {
    return (
      <li key={`${student.fullname}${student.school}`}>
        <div>
          <b>Full name:</b> {student.fullname}
        </div>
        <div>
          <b>School:</b> {student.school}
        </div>
      </li>
    );
  });
};
