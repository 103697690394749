import React, { useEffect } from "react";
import styles from "./PaymentDetailModal.module.css";
import InputField from "../../components/inputField/InputField.js";
import { useDispatch, useSelector } from "react-redux";
import Close from "@material-ui/icons/Close";
import {
  clearCart,
  selectNamesOfImagesForDb,
  selectPriceOfCart,
} from "../../app/reducers/CartSlice";
import DropdownList from "../../components/dropdownList/DropdownList";
import Button from "../../components/button/Button";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import {
  setFirstName,
  selectFirstName,
  selectShowModal,
  toggleModal,
  selectSurname,
  setSurname,
  setPaymentMethod,
  selectPaymentMethod,
  selectPaymentOptions,
  selectPayfastSignature,
  getMD5Signature,
  selectInitiateGetSignature,
  setMD5Signature,
  setIsSignatureLoading,
  setIsSignatureError,
  selectPaymentId,
  setPaymentId,
  selectIsSignatureError,
  selectIsSignatureLoading,
} from "../../app/reducers/CheckoutSlice";
import { selectEmail, selectUsername } from "../../app/reducers/UserSlice";
import { useHistory } from "react-router";

const PaymentDetailModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // User slice
  const emailAddress = useSelector(selectEmail);

  // Checkout slice
  const showModal = useSelector(selectShowModal);
  const username = useSelector(selectUsername);
  const firstName = useSelector(selectFirstName);
  const surname = useSelector(selectSurname);
  const paymentMethod = useSelector(selectPaymentMethod);
  const paymentOptions = useSelector(selectPaymentOptions);
  const isSignatureError = useSelector(selectIsSignatureError);
  const isSignatureLoading = useSelector(selectIsSignatureLoading);

  const paymentId = useSelector(selectPaymentId);
  const amountToPay = useSelector(selectPriceOfCart);
  const itemName = "Old School Photos";

  const imagesInCart = useSelector(selectNamesOfImagesForDb);

  const payfastSignature = useSelector(selectPayfastSignature);
  const initiateGetSignature = useSelector(selectInitiateGetSignature);
  const modalStyle = showModal ? styles.modalBackground : styles.hide;

  useEffect(() => {
    if (!paymentId) {
      dispatch(setPaymentId(uuidv4()));
    }
    if (initiateGetSignature) {
      dispatch(getMD5Signature(false));

      const merchantId = process.env.REACT_APP_PAYFAST_MERCHANT_ID;
      const merchantKey = process.env.REACT_APP_PAYFAST_MERCHANT_KEY;
      const returnUrl = process.env.REACT_APP_PAYFAST_RETURN_URL;
      const cancelUrl = process.env.REACT_APP_PAYFAST_CANCEL_URL;
      const notifyUrl = process.env.REACT_APP_PAYFAST_NOTIFY_URL;

      const reqObj = {
        merchant_id: merchantId,
        merchant_key: merchantKey,
        return_url: returnUrl,
        cancel_url: cancelUrl,
        notify_url: notifyUrl,
        name_first: firstName,
        name_last: surname,
        email_address: emailAddress,
        m_payment_id: paymentId,
        amount: amountToPay,
        item_name: itemName,
        custom_str1: username,
        payment_method: paymentMethod,
        images_in_cart: imagesInCart,
      };

      dispatch(setIsSignatureLoading(true));

      API.post(
        "getPayfastSignatureAndCreatePayment",
        "/getPayfastSignatureAndCreatePayment",
        {
          body: reqObj,
        }
      )
        .then((res) => {
          dispatch(setMD5Signature(res.signature));
          dispatch(setIsSignatureLoading(false));

          if (res.isWhitelisted) {
            dispatch(clearCart());
            history.push("/Orders");
          } else {
            document.getElementById("hidden-payfast-form").submit();
          }
        })
        .catch((error) => {
          dispatch(setIsSignatureLoading(false));
          dispatch(setIsSignatureError(true));
        });
    }
  });

  const disableButton = () => {
    return !firstName || !surname || !emailAddress || !paymentMethod;
  };

  return (
    <div className={modalStyle}>
      <div className={styles.modal}>
        <span
          className={styles.closeIcon}
          onClick={() => dispatch(toggleModal())}
        >
          <Close style={{ fontSize: "40px" }} />
        </span>
        <InputField
          name='firstName'
          type='firstName'
          labelText='First Name'
          value={firstName}
          handleChange={(event) => dispatch(setFirstName(event.target.value))}
        />
        <InputField
          name='surname'
          type='surname'
          labelText='Surname'
          value={surname}
          handleChange={(event) => dispatch(setSurname(event.target.value))}
        />
        <DropdownList
          id='paymentMethod'
          label='Please select a payment method'
          dropdownOptions={paymentOptions}
          value={paymentMethod}
          defaultText='Select a payment method'
          onSelect={(e) => dispatch(setPaymentMethod(e.target.value))}
        />
        <Button
          onClick={() => dispatch(getMD5Signature(true))}
          isPrimary={true}
          isRedirect={false}
          buttonText='Proceed to PayFast'
          disabled={disableButton()}
        ></Button>

        {isSignatureError && (
          <p className={styles.errorMessage}>
            Something went wrong. Please ensure all fields are filled out
            correctly.
          </p>
        )}

        {isSignatureLoading && (
          <div className={styles.loaderContainer}>
            <img
              alt='loading'
              className={styles.loader}
              src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
            />
          </div>
        )}
      </div>

      <form
        id='hidden-payfast-form'
        action={process.env.REACT_APP_PAYFAST_URL}
        method='POST'
      >
        <input
          type='hidden'
          name='merchant_id'
          value={process.env.REACT_APP_PAYFAST_MERCHANT_ID}
        />
        <input
          type='hidden'
          name='merchant_key'
          value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY}
        />
        <input
          type='hidden'
          name='return_url'
          value={process.env.REACT_APP_PAYFAST_RETURN_URL}
        />
        <input
          type='hidden'
          name='cancel_url'
          value={process.env.REACT_APP_PAYFAST_CANCEL_URL}
        />
        <input
          type='hidden'
          name='notify_url'
          value={process.env.REACT_APP_PAYFAST_NOTIFY_URL}
        />
        <input type='hidden' name='name_first' value={firstName || ""} />
        <input type='hidden' name='name_last' value={surname || ""} />
        <input type='hidden' name='email_address' value={emailAddress || ""} />
        <input type='hidden' name='m_payment_id' value={paymentId || ""} />
        <input type='hidden' name='amount' value={amountToPay || ""} />
        <input type='hidden' name='item_name' value={itemName || ""} />
        <input type='hidden' name='custom_str1' value={username || ""} />
        <input
          type='hidden'
          name='payment_method'
          value={paymentMethod || ""}
        />
        <input type='hidden' name='signature' value={payfastSignature || ""} />
      </form>
    </div>
  );
};

export default PaymentDetailModal;
