import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { selectEmail, selectUsername } from "../../app/reducers/UserSlice";
import PuffLoader from "react-spinners/PuffLoader";
import PastSearchImageCard from "../../components/pastSearchImageCard/PastSearchImageCard";
import styles from "./PastSearchImages.module.css";

import ErrorIcon from "@material-ui/icons/ErrorOutline";
import SearchIcon from "@material-ui/icons/PersonOutline";
import { override } from "../../utils/LoaderOverride";
import Button from "../button/Button";

const PastSearchImages = ({ goBack }) => {
  const email = useSelector(selectEmail);
  const username = useSelector(selectUsername);

  const [searchesError, setSearchesError] = useState("");
  const [isLoadingSearches, setIsLoadingSearches] = useState(true);
  const [pastSearchImageKeysAndUrls, setPastSearchImageKeysAndUrls] = useState(
    []
  );

  useEffect(() => {
    getPastSearchImages();
  }, []);

  const getPastSearchImages = () => {
    setSearchesError("");
    setIsLoadingSearches(true);
    API.post("getPastSearches", "/getPastSearches", {
      body: { username: username, email: email },
    })
      .then((res) => {
        setPastSearchImageKeysAndUrls(res);
        setIsLoadingSearches(false);
      })
      .catch(() => {
        setIsLoadingSearches(false);
        setSearchesError("Oops, something went wrong. Please try again.");
      });
  };

  return (
    <div className={styles.searchWrapper}>
      <h2>Past image uploads</h2>
      {!searchesError &&
        !isLoadingSearches &&
        pastSearchImageKeysAndUrls.length > 0 && (
          <p>
            Searching for these images again do not count to your total number
            of searches
          </p>
        )}

      <div className={styles.pastSearchesContainer}>
        {isLoadingSearches && (
          <div className={styles.searchesErrorContainer}>
            <PuffLoader
              css={override}
              size={100}
              color={"#484848"}
              radius={"0.5em"}
              loading={true}
            />
            <p>Loading previous search images...</p>
          </div>
        )}
        {!isLoadingSearches && searchesError && (
          <div className={styles.searchesErrorContainer}>
            <div className={styles.errorIconContainer}>
              <ErrorIcon></ErrorIcon>
            </div>
            <p>{searchesError}</p>
          </div>
        )}
        {!searchesError &&
          !isLoadingSearches &&
          !pastSearchImageKeysAndUrls.length && (
            <div className={styles.searchesErrorContainer}>
              <div className={styles.errorIconContainer}>
                <SearchIcon></SearchIcon>
              </div>
              <p>You have not made any searches</p>
            </div>
          )}

        {!searchesError &&
          !isLoadingSearches &&
          pastSearchImageKeysAndUrls.length > 0 && (
            <div className={styles.imagesContainer}>
              {pastSearchImageKeysAndUrls.map((keyAndUrl) => {
                return (
                  <div key={keyAndUrl.key} className={styles.imageCard}>
                    <PastSearchImageCard
                      keyAndUrl={keyAndUrl}
                      height={window.innerWidth > 600 ? 400 : 200}
                    />
                  </div>
                );
              })}
            </div>
          )}
      </div>
      {!isLoadingSearches && goBack && (
        <div className={styles.goBackButtonContainer}>
          <Button
            isRedirect={false}
            buttonText='Back'
            disabled={false}
            onClick={() => goBack()}
            isPrimary={true}
          />
        </div>
      )}
    </div>
  );
};

export default PastSearchImages;
