import React from "react";
import styles from "./DropdownList.module.css";

const DropdownList = ({
  id,
  label,
  dropdownOptions,
  value,
  onSelect,
  hintText,
  defaultText,
  disabled,
}) => {
  let options = [
    <option value='' key='default'>
      {defaultText}
    </option>,
  ];
  const selectOptions = dropdownOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
  options = options.concat(selectOptions);

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.labelContainer}>
        <label
          style={!value || value === "" ? { visibility: "hidden" } : {}}
          className={styles.labelFont}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      <select
        className={styles.dropdown}
        name={id}
        id={id}
        value={value === null ? "" : value}
        onChange={onSelect}
        disabled={disabled}
      >
        {options}
      </select>
      {hintText && (
        <div className={styles.hintTextContainer}>
          <div className={styles.hintTextFont}>{hintText}</div>
        </div>
      )}
    </div>
  );
};
export default DropdownList;
