import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import styles from "./Admin.module.css";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";
import PuffLoader from "react-spinners/PuffLoader";
import { override } from "../../utils/LoaderOverride";

const Transactions = ({ goBackToList }) => {
  const username = useSelector(selectUsername);

  const [transactionsError, setTransactionsError] = useState("");
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [previousTransactions, setPreviousTransactions] = useState([]);

  const getTransactions = (type) => {
    setIsLoadingTransactions(true);
    setTransactionsError("");
    const reqObj = { username: username, transactionStatus: type };
    API.post("getListOfTransactions", "/getListOfTransactions", {
      body: reqObj,
    })
      .then((res) => {
        setIsLoadingTransactions(false);
        if (res.transactions.Count > 0) {
          setPreviousTransactions(res.transactions.Items);
        } else {
          setTransactionsError("No transactions");
        }
      })
      .catch((err) => {
        window.alert(`Error loading transactions: ${err}`);
        setIsLoadingTransactions(false);
        setTransactionsError("Error fetching transactions");
      });
  };

  const dateToString = (date) => {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      date.getFullYear(),
    ].join("/");
  };

  const goBack = () => {
    goBackToList();
  };

  const formatTransactions = () => {
    if (!previousTransactions.length) return;
    return (
      <>
        <h3>User transactions</h3>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Order Number</th>
              <th>Amount Paid</th>
              <th>Payment method</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {previousTransactions.map((transaction) => {
              const date =
                typeof transaction.dateOfOrder == "string" &&
                new Date(parseInt(transaction.dateOfOrder));

              return (
                <tr>
                  <td>{date && dateToString(date)}</td>
                  <td>{transaction.orderNumber}</td>
                  <td>{transaction.amountPaid}</td>
                  <td>{transaction.paymentMethod}</td>
                  <td>{transaction.paymentStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className={styles.AdminContainer}>
      <h2 className={styles.headingContainer}>Transactions</h2>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => goBack()}
          isRedirect={false}
          buttonText='Back'
          isPrimary={true}
        />
      </div>

      <div className={styles.transactionsHeaderBlock}>
        <p>What type of transaction would you like to see?</p>
        <div className={styles.transactionsHeaderRow}>
          <Button
            onClick={() => getTransactions("COMPLETE")}
            isRedirect={false}
            buttonText='COMPLETE'
            isPrimary={true}
          />
          <Button
            onClick={() => getTransactions("CANCELLED")}
            isRedirect={false}
            buttonText='CANCELLED'
            isPrimary={true}
          />
          <Button
            onClick={() => getTransactions("INVESTIGATE")}
            isRedirect={false}
            buttonText='INVESTIGATE'
            isPrimary={true}
          />
        </div>
        <div className={styles.transactionsHeaderRow}>
          <Button
            onClick={() => getTransactions("IN PROGRESS")}
            isRedirect={false}
            buttonText='IN PROGRESS'
            isPrimary={true}
          />
          <Button
            onClick={() => getTransactions()}
            isRedirect={false}
            buttonText='ALL'
            isPrimary={true}
          />
        </div>
      </div>
      <div className={styles.transactions}>
        {isLoadingTransactions && (
          <div className={styles.searchesErrorContainer}>
            <PuffLoader
              css={override}
              size={100}
              color={"#484848"}
              radius={"0.5em"}
              loading={true}
            />
            <p>Loading transactions...</p>
          </div>
        )}

        {!isLoadingTransactions && transactionsError && (
          <div className={styles.searchesErrorContainer}>
            <p>{transactionsError}</p>
          </div>
        )}

        {!isLoadingTransactions && !transactionsError && formatTransactions()}
      </div>
    </div>
  );
};
export default Transactions;
