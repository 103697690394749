import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentDetailModal from "./PaymentDetailModal";
import styles from "./Checkout.module.css";
import {
  clearCart,
  selectCurrentImagePrice,
  selectImagesInCart,
  selectPriceOfCart,
} from "../../app/reducers/CartSlice";
import { toggleModal } from "../../app/reducers/CheckoutSlice";
import Button from "../../components/button/Button";
import ImageCard from "../../components/imageCard/ImageCard";
import Basket from "@material-ui/icons/ShoppingCart";
import { selectPreviewImageObjects } from "../../app/reducers/UploaderSlice";

const Checkout = () => {
  const dispatch = useDispatch();

  const previewImageObjects = useSelector(selectPreviewImageObjects);
  const imagesInCart = useSelector(selectImagesInCart);
  const amountToPay = useSelector(selectPriceOfCart);
  const pricePerPicture = useSelector(selectCurrentImagePrice);

  const handleSubmit = () => {
    dispatch(toggleModal());
  };

  const removeAllImagesFromCart = () => {
    dispatch(clearCart());
  };

  const imageObjects = imagesInCart.map((imageObject, index) => {
    return (
      <div key={index} className={styles.imageCard}>
        <ImageCard
          imageName={imageObject.key}
          imageKey={imageObject.key}
          presignedUrl={imageObject.presignedUrl}
          showAddToCart={true}
          height={window.innerWidth > 900 ? 215 : 150}
        />
      </div>
    );
  });

  const innerHtml =
    imagesInCart.length === 0 ? (
      <div className={styles.noImagesContainer}>
        <div className={styles.noImagesContentWrapper}>
          <Basket className={styles.emptyIcon}></Basket>
          <div>Looks like your card is empty</div>
          <div className={styles.buttonContainer}>
            <Button
              isRedirect={true}
              path='/upload'
              buttonText='Search for images'
              isPrimary={true}
              disabled={false}
            />
          </div>
          {previewImageObjects.length > 0 && (
            <div className={styles.buttonContainer}>
              <Button
                isRedirect={true}
                path='/images'
                buttonText='Back to search results'
                isPrimary={false}
                disabled={false}
              />
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.collectionContainer}>
        <PaymentDetailModal />
        <div className={styles.collectionContent}>
          <div className={styles.orderSummary}>
            <h5>Order Summary</h5>
            <div className={styles.summaryRow}>
              <p>Quantity:</p>
              <p className={styles.summaryValue}>
                <b>{imagesInCart.length}</b>
              </p>
            </div>
            <div className={styles.summaryRow}>
              <p>Price:</p>
              <p className={styles.summaryValue}>
                <b>R {amountToPay}</b>
              </p>
            </div>
            <div className={styles.summaryRow}>
              <p>Price per picture:</p>
              <p className={styles.summaryValue}>
                <b>R {pricePerPicture}</b>
              </p>
            </div>
            <Button
              onClick={() => handleSubmit()}
              isRedirect={false}
              isPrimary={true}
              buttonText='Proceed to payment'
              disabled={false}
            ></Button>
            <Button
              onClick={() => removeAllImagesFromCart()}
              isPrimary={false}
              isRedirect={false}
              disabled={imagesInCart.length === 0}
              buttonText={"Remove all images in cart"}
            ></Button>
          </div>
          <div className={styles.orderContents}>{imageObjects}</div>
        </div>
      </div>
    );

  return (
    <div className={styles.checkoutContainer}>
      <h2>Checkout</h2>
      {innerHtml}
    </div>
  );
};

export default Checkout;
