import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";

import InputField from "../../components/inputField/InputField";
import Button from "../../components/button/Button";

import styles from "./Admin.module.css";
import UserProfile from "./UserProfile";
import CreateUser from "./CreateUser";
import Transactions from "./Transactions";
import SendInviteEmail from "./SendInviteEmail";
import Students from "./Students";
import { formatVerifiedStudents } from "./utils";

const Admin = () => {
  const username = useSelector(selectUsername);

  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [viewState, setViewState] = useState("list");
  const [currentUser, setCurrentUser] = useState({});
  const [freePurchasesEmail, setFreePurchasesEmail] = useState("");
  const [listOfFreeUsers, setListOfFreeUsers] = useState([]);

  useEffect(() => {
    getUserDetails();
    getListOfFreeUsers();
  }, []);

  const getUserDetails = () => {
    API.post("getUserDetails", "/getUserDetails", {
      body: {
        username: username,
      },
    })
      .then((res) => {
        if (res.errorMessage) {
          setError(res.errorMessage);
          return;
        }

        if (!res.users) {
          setError("No users were returned");
          return;
        }

        setUsers(
          res.users
            .map((user) => {
              const verifiedStudents = user.Attributes.filter(
                (att) => att.Name === "custom:verifiedStudents"
              )[0]?.Value;

              const parsedStudents =
                !verifiedStudents || !verifiedStudents.length
                  ? []
                  : JSON.parse(verifiedStudents);
              return {
                username: user.Username,
                fullname: user.Attributes.filter(
                  (att) => att.Name === "custom:fullname"
                )[0]?.Value,
                email: user.Attributes.filter((att) => att.Name === "email")[0]
                  ?.Value,
                schools: user.Attributes.filter(
                  (att) => att.Name === "custom:School1"
                )[0]?.Value,
                verifiedStudents: parsedStudents,
                userStatus: user.UserStatus,
              };
            })
            .sort((user1, user2) => user1.email.localeCompare(user2.email))
        );
      })
      .catch((err) => {
        window.alert(`Error getting users: ${err}`);
        setError(err.errorMessage);
      });
  };

  const getListOfFreeUsers = () => {
    API.post("getListOfFreeUsers", "/getListOfFreeUsers", {
      body: {},
    })
      .then((res) => {
        setListOfFreeUsers(res.emails);
      })
      .catch((err) => {
        window.alert(`Error getting list of free users: ${err}`);
      });
  };

  const freePurchasesHandler = (isAdding) => {
    const reqObj = {
      username: username,
      emailToAddRemove: freePurchasesEmail,
      isAdding: isAdding,
    };
    API.post("addRemoveFreePurchaseUser", "/addRemoveFreePurchaseUser", {
      body: reqObj,
    })
      .then(() => {
        setFreePurchasesEmail("");
        getListOfFreeUsers();
        window.alert("Success");
      })
      .catch((err) => {
        window.alert(`Error: ${err}`);
      });
  };

  const selectUser = (user) => {
    setCurrentUser(user);
    setViewState("user");
  };

  const goToCreate = (user) => {
    setCurrentUser(user);
    setViewState("create");
  };

  const goToViewState = (viewState) => {
    setViewState(viewState);
  };

  const formatListOfFreeUsers = () => {
    if (!listOfFreeUsers.length) return;
    return (
      <div className={styles.freePurchaseList}>
        <h3>Users with free purchases</h3>
        {listOfFreeUsers.map((freeUser) => {
          return <div key={freeUser.email}>{freeUser.email}</div>;
        })}
        <hr></hr>
      </div>
    );
  };

  const showListView = () => {
    return (
      <div className={styles.AdminContainer}>
        <h2 className={styles.headingContainer}>Admin Console</h2>
        <div className={styles.addUserToFreePurchaseBlock}>
          <Button
            onClick={() => {
              setCurrentUser(undefined);
              goToViewState("create");
            }}
            isRedirect={false}
            isPrimary={true}
            buttonText='Create new user'
          />

          <Button
            onClick={() => {
              goToViewState("sendInvite");
            }}
            isRedirect={false}
            isPrimary={false}
            buttonText='Send invite email'
          />
        </div>
        <hr></hr>
        <div className={styles.addUserToFreePurchaseBlock}>
          <Button
            onClick={() => {
              goToViewState("viewStudentsDatabase");
            }}
            isRedirect={false}
            isPrimary={true}
            buttonText='View past students database'
          />
        </div>
        <hr></hr>

        <div className={styles.addUserToFreePurchaseBlock}>
          <InputField
            name='email'
            type='email'
            labelText='Email Address for free purchases'
            value={freePurchasesEmail}
            handleChange={(event) => setFreePurchasesEmail(event.target.value)}
          />
          <Button
            onClick={() => freePurchasesHandler(true)}
            isRedirect={false}
            disabled={!freePurchasesEmail}
            isPrimary={true}
            buttonText='Add to free purchases'
          />

          <Button
            onClick={() => freePurchasesHandler(false)}
            isRedirect={false}
            isPrimary={false}
            disabled={!freePurchasesEmail}
            buttonText='Remove from free purchases'
          />
        </div>
        <hr></hr>
        <div className={styles.addUserToFreePurchaseBlock}>
          <Button
            onClick={() => goToViewState("transactions")}
            isRedirect={false}
            isPrimary={false}
            buttonText='View transactions'
          />
        </div>
        <hr></hr>
        {formatListOfFreeUsers()}

        <div className={styles.contentContainer}>
          {error?.length === 0 ? (
            <>
              <p>Number of users: {users.length}</p>
              <table>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Full name</th>
                    <th>User status</th>
                    <th>Schools</th>
                    <th>Verified students</th>
                  </tr>
                </thead>
                <tbody>
                  {users.length > 0 &&
                    users.map((user) => {
                      return (
                        <tr key={user.email}>
                          <td>
                            <button onClick={() => selectUser(user)}>
                              {user.email}
                            </button>
                          </td>
                          <td>{user.fullname}</td>

                          {user.userStatus === "FORCE_CHANGE_PASSWORD" ? (
                            <td>
                              <button onClick={() => goToCreate(user)}>
                                {user.userStatus}
                              </button>
                            </td>
                          ) : (
                            <td>{user.userStatus}</td>
                          )}
                          <td>{user.schools}</td>
                          <td>
                            <ul>
                              {formatVerifiedStudents(user.verifiedStudents)}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </>
          ) : (
            <>{error}</>
          )}
        </div>
      </div>
    );
  };

  const showUserView = () => {
    return (
      <UserProfile
        goBackToList={() => setViewState("list")}
        user={currentUser}
      ></UserProfile>
    );
  };

  const showSendInvite = () => {
    return (
      <SendInviteEmail
        goBackToList={() => setViewState("list")}
      ></SendInviteEmail>
    );
  };

  const showCreateView = () => {
    return (
      <CreateUser
        user={currentUser}
        goBackToList={() => setViewState("list")}
      ></CreateUser>
    );
  };

  const showTransactionView = () => {
    return (
      <Transactions goBackToList={() => setViewState("list")}></Transactions>
    );
  };

  const showStudentView = () => {
    return <Students goBackToList={() => setViewState("list")}></Students>;
  };

  if (viewState === "sendInvite") return showSendInvite();
  if (viewState === "user") return showUserView();
  if (viewState === "create") return showCreateView();
  if (viewState === "transactions") return showTransactionView();
  if (viewState === "viewStudentsDatabase") return showStudentView();

  return showListView();
};
export default Admin;
