import React from "react";
import styles from "./Home.module.css";
import Button from "../../components/button/Button";
import { selectIsAuthenticated } from "../../app/reducers/UserSlice";
import { useSelector } from "react-redux";

const Home = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <div className={styles.homeContainer}>
      <img alt='logo' src='/OldSchoolPhotosLogo.png' className={styles.logo} />

      <div className={styles.textContainer}>
        <h1>Welcome to your school memory bank</h1>

        <h4>Leading the way with facial recognition</h4>
        <p>
          Inside you will find all the official photos taken at Reddam
          Constantia and Reddam Atlantic Seaboard between 2002 and 2013 and
          Micklefield between 2010 and 2013.
          {!isAuthenticated && <> Enjoy the trip to the past!</>}
        </p>

        {!isAuthenticated && (
          <p>
            Unlock your school memories by <a href='/register'>registering</a>{" "}
            or <a href='/login'>logging in</a>.
          </p>
        )}

        {isAuthenticated && <p>Get ready to travel back to school!</p>}

        <h6 className={styles.topMargin}>
          For images images taken after 2013, contact That's Me Photography -{" "}
          <a href='mailto:info@thatsme.co.za?subject=Access to Photos After 2013'>
            info@thatsme.co.za
          </a>
        </h6>

        <div className={styles.buttonContainer}>
          <Button
            path={isAuthenticated ? "/upload" : "/register"}
            isRedirect={true}
            buttonText='Take me to my photos!'
            isPrimary={true}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            path={"/about-us"}
            isRedirect={true}
            buttonText='Learn more'
            isPrimary={false}
          />
        </div>
        <p>
          Contact us -{" "}
          <a href='mailto:info@oldschoolphotos.co.za'>
            info@oldschoolphotos.co.za
          </a>
        </p>
      </div>
    </div>
  );
};

export default Home;
