import { createSlice } from "@reduxjs/toolkit";

export const checkoutSlice = createSlice({
  name: "checkoutModel",
  initialState: {
    firstName: null,
    surname: null,
    cellphoneNumber: null,
    amount: null,
    paymentMethod: null,
    payfastSignature: null,
    showModal: false,
    loadingSignature: false,
    signatureError: false,
    initiateGetSignature: false,
    paymentId: "",
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setSurname: (state, action) => {
      state.surname = action.payload;
    },
    setCellphoneNumber: (state, action) => {
      state.cellphoneNumber = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    toggleModal: (state, action) => {
      state.showModal = !state.showModal;
    },
    setPaymentId: (state, action) => {
      state.paymentId = action.payload;
    },
    getMD5Signature: (state, action) => {
      state.initiateGetSignature = action.payload;
    },
    setMD5Signature: (state, action) => {
      state.payfastSignature = action.payload;
    },
    setIsSignatureLoading: (state, action) => {
      state.loadingSignature = action.payload;
    },
    setIsSignatureError: (state, action) => {
      state.signatureError = action.payload;
    },
  },
});

export const {
  setFirstName,
  setSurname,
  setCellphoneNumber,
  setPaymentMethod,
  getMD5Signature,
  setMD5Signature,
  setIsSignatureError,
  setIsSignatureLoading,
  toggleModal,
  setPaymentId,
} = checkoutSlice.actions;

export const selectFirstName = (state) => state.checkoutModel.firstName;
export const selectSurname = (state) => state.checkoutModel.surname;
export const selectCellphoneNumber = (state) =>
  state.checkoutModel.cellphoneNumber;
export const selectPaymentMethod = (state) => state.checkoutModel.paymentMethod;
export const selectPaymentOptions = (state) => [
  { value: "cc", label: "Credit card" },
  { value: "dc", label: "Debit card" },
  { value: "eft", label: "Instant EFT" },
  { value: "mp", label: "Masterpass" },
  { value: "ss", label: "SnapScan" },
  { value: "zp", label: "Zapper" },
];

export const selectPaymentId = (state) => state.checkoutModel.paymentId;
export const selectPayfastSignature = (state) =>
  state.checkoutModel.payfastSignature;
export const selectShowModal = (state) => state.checkoutModel.showModal;
export const selectInitiateGetSignature = (state) =>
  state.checkoutModel.initiateGetSignature;
export const selectIsSignatureError = (state) =>
  state.checkoutModel.signatureError;
export const selectIsSignatureLoading = (state) =>
  state.checkoutModel.loadingSignature;

export default checkoutSlice.reducer;
