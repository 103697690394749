import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";


export default function UnauthenticatedRoute({ children, ...rest }) {
  return (
    <Route {...rest}>
      {children}
    </Route>
  );
}