import React, { useState } from "react";
import { API } from "aws-amplify";

import styles from "./PastSearchImageCard.module.css";
import MoonLoader from "react-spinners/MoonLoader";

import BrokenImage from "@material-ui/icons/BrokenImage";
import Button from "../button/Button";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import {
  setAvoidSearchLimit,
  setShouldSearchAgain,
  setUpload,
  setUploadFileName,
} from "../../app/reducers/UploaderSlice";
import { logBugsnagError } from "../../utils/Bugsnag";
import { override } from "../../utils/LoaderOverride";

const PastSearchImageCard = ({ keyAndUrl, height }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isFetchingBlob, setIsFetchingBlob] = useState(false);

  const searchAgain = () => {
    dispatch(setShouldSearchAgain(true));
    dispatch(setAvoidSearchLimit(true));
    setIsFetchingBlob(true);

    API.post("getPastSearchImageObject", "/getPastSearchImageObject", {
      body: { key: keyAndUrl.key },
    })
      .then((keyImagePair) => {
        dispatch(setUploadFileName(keyAndUrl.key));
        dispatch(setUpload(keyImagePair.img));
        history.push("/images");
      })
      .catch((err) => {
        logBugsnagError(err);
        setIsFetchingBlob(false);
      });
  };

  const imageStyling = { height: `${height}px` };

  if (isError) {
    return (
      <div className={styles.errorContainer} style={imageStyling}>
        <BrokenImage />
        <p>Could not load image</p>
      </div>
    );
  }

  return (
    <>
      <div
        className={styles.errorContainer}
        style={{
          ...imageStyling,
          display: isImageLoading ? "" : "none",
        }}
      >
        <MoonLoader css={override} size={80} color={"#484848"} loading={true} />
      </div>
      <div
        className={styles.cardContainer}
        style={{
          visibility: isImageLoading ? "hidden" : "",
          height: isImageLoading ? "0" : "",
        }}
      >
        <div className={styles.imageCard}>
          <img
            src={keyAndUrl.presignedUrl}
            className={styles.image}
            alt='Previously searched'
            style={isImageLoading ? { height: "0" } : imageStyling}
            onLoad={() => setIsImageLoading(false)}
            onError={() => setIsError(true)}
          ></img>
          <div className={styles.searchAgain}>
            <Button
              disabled={!keyAndUrl.presignedUrl || isImageLoading}
              buttonText={"Search again"}
              onClick={() => searchAgain()}
              isLoading={isFetchingBlob}
            ></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PastSearchImageCard;
