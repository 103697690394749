import React, { useState } from "react";

import styles from "../Login/Login.module.css";
import Button from "../../components/button/Button";
import InputField from "../../components/inputField/InputField";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRegistrationEmail,
  selectRegistrationPassword,
  setRegistrationEmail,
  setRegistrationPassword,
} from "../../app/reducers/RegisterSlice";
import { selectFullname, setFullname } from "../../app/reducers/UserSlice";

const PersonalDetails = ({ onCompleteEvent }) => {
  const dispatch = useDispatch();

  const email = useSelector(selectRegistrationEmail);
  const password = useSelector(selectRegistrationPassword);
  const fullname = useSelector(selectFullname);

  const [confirmPassword, setConfirmPassword] = useState("");

  const emailAddressErrorMessage = () => {
    if (email !== "") {
      const regex = new RegExp(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,10}$/);
      if (!regex.test(email)) {
        return "Please use a valid email address";
      }
    }
    return "";
  };

  const passwordErrorMessage = () => {
    if (password !== "") {
      const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
      if (!regex.test(password)) {
        return "Required: 6 characters, one uppercase and lowercase letter and a number.";
      }
    }
    return "";
  };

  const confirmPasswordErrorMessage = () => {
    if (password !== "" && confirmPassword !== password) {
      return "Passwords do not match.";
    }
    return "";
  };

  const validateForm = () => {
    return (
      fullname.length > 0 &&
      email.length > 0 &&
      password.length > 6 &&
      confirmPassword.length > 6 &&
      password === confirmPassword
    );
  };

  const onButtonClick = () => {
    onCompleteEvent(1);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <h2 className={styles.headingFont}>Register</h2>
      </div>
      <InputField
        name='fullname'
        type='text'
        labelText='Full name'
        value={fullname}
        handleChange={(event) => dispatch(setFullname(event.target.value))}
      />
      <InputField
        name='email'
        type='email'
        labelText='Email Address'
        value={email}
        handleChange={(event) =>
          dispatch(setRegistrationEmail(event.target.value))
        }
        showError={emailAddressErrorMessage().length > 0}
        errorMessage={emailAddressErrorMessage()}
      />
      <InputField
        name='password'
        type='password'
        labelText='Password'
        value={password}
        handleChange={(event) =>
          dispatch(setRegistrationPassword(event.target.value))
        }
        showError={passwordErrorMessage().length > 0}
        errorMessage={passwordErrorMessage()}
        isPasswordField={true}
      />
      <InputField
        name='confirmPassword'
        type='password'
        labelText='Confirm Password'
        value={confirmPassword}
        handleChange={(event) => setConfirmPassword(event.target.value)}
        showError={confirmPasswordErrorMessage().length > 0}
        errorMessage={confirmPasswordErrorMessage()}
        isPasswordField={true}
      />
      <Button
        onClick={() => onButtonClick()}
        isRedirect={false}
        disabled={!validateForm()}
        buttonText='Next'
        isPrimary={true}
      />
      <Button
        path='/confirm_registration'
        isRedirect={true}
        disabled={false}
        buttonText='Resend or Confirm Code'
        isPrimary={false}
      />
    </>
  );
};

export default PersonalDetails;
