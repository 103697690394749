import React, { useState } from "react";
import styles from "../Login/Login.module.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFullname,
  setUserAuthentication,
} from "../../app/reducers/UserSlice";
import { Auth } from "aws-amplify";
import PersonalDetails from "./PersonalDetails";
import VerifyStudent from "./VerifyStudent";
import Recaptcha from "./Recaptcha";
import {
  selectRegistrationEmail,
  selectRegistrationPassword,
  selectRegistrationSchools,
  selectRegistrationVerifiedStudents,
} from "../../app/reducers/RegisterSlice";
import ConfirmationCode from "./ConfirmationCode";
import { logBugsnagError } from "../../utils/Bugsnag";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const email = useSelector(selectRegistrationEmail);
  const password = useSelector(selectRegistrationPassword);
  const schools = useSelector(selectRegistrationSchools);
  const verifiedStudents = useSelector(selectRegistrationVerifiedStudents);
  const fullname = useSelector(selectFullname);

  const [currentStep, setCurrentStep] = useState(0);
  const [registerErrorMessage, setRegisterErrorMessage] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [hasCompletedCaptcha, setHasCompletedCaptcha] = useState(false);

  const registerUser = (event) => {
    setIsRegistering(true);
    setRegisterErrorMessage("");

    const filteredSchools = schools
      .filter((school) => school !== "")
      .filter((v, i, a) => a.indexOf(v) === i);

    Auth.signUp({
      username: email,
      password: password,
      attributes: {
        "custom:fullname": fullname,
        "custom:School1": filteredSchools.join(","),
        "custom:verifiedStudents": JSON.stringify(verifiedStudents),
      },
    })
      .then(() => {
        setIsRegistered(true);
        setCurrentStep(4);
        setIsRegistering(false);
      })
      .catch((err) => {
        if (err?.message?.includes("The value of the attribute custom")) {
          logBugsnagError(err.message);
        }
        setRegisterErrorMessage(
          "Something went wrong. Please try again and make sure your details are correct."
        );
        setCurrentStep(0);
        setIsRegistering(false);
        dispatch(setUserAuthentication(false));
      });
  };

  const updateCurrentStep = (updateValue) => {
    if (updateValue === 2 && hasCompletedCaptcha) {
      onRecaptchaComplete();
      return;
    }
    setCurrentStep(updateValue);
  };

  const onRecaptchaComplete = () => {
    setHasCompletedCaptcha(true);
    setCurrentStep(3);
    registerUser();
  };

  const completeRegistration = () => {
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <div className={styles.loginContainer}>
      {currentStep === 0 && (
        <>
          <PersonalDetails
            onCompleteEvent={updateCurrentStep}
          ></PersonalDetails>
          {!!registerErrorMessage.length && (
            <>
              <div className={styles.loginErrorMessage}>
                {registerErrorMessage}
              </div>
            </>
          )}
        </>
      )}
      {currentStep === 1 && (
        <VerifyStudent onCompleteEvent={updateCurrentStep}></VerifyStudent>
      )}
      {currentStep === 2 && (
        <Recaptcha onCompleteEvent={onRecaptchaComplete}></Recaptcha>
      )}

      {currentStep === 3 && isRegistering && (
        <div>
          <div className={styles.loaderContainer}>
            <img
              alt='loading'
              class={styles.loader}
              src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
            />
          </div>
          <p>Creating your account</p>
        </div>
      )}

      {isRegistered && currentStep === 4 && (
        <ConfirmationCode
          onCompleteEvent={completeRegistration}
        ></ConfirmationCode>
      )}
    </div>
  );
};
export default Register;
