import { createSlice } from "@reduxjs/toolkit";

export const registerSlice = createSlice({
  name: "registerModel",
  initialState: {
    email: "",
    password: "",
    studentFirstname: "",
    studentSurname: "",
    schools: [""],
    verifiedStudents: [],
  },
  reducers: {
    setRegistrationEmail: (state, action) => {
      state.email = action.payload;
    },
    setRegistrationPassword: (state, action) => {
      state.password = action.payload;
    },
    setRegistrationSchools: (state, action) => {
      state.schools = action.payload;
    },
    setRegistrationVerifiedStudents: (state, action) => {
      state.verifiedStudents = action.payload;
    },
    setRegistrationStudentFirstname: (state, action) => {
      state.studentFirstname = action.payload;
    },
    setRegistrationStudentSurnname: (state, action) => {
      state.studentSurname = action.payload;
    },
  },
});

export const {
  setRegistrationEmail,
  setRegistrationPassword,
  setRegistrationSchools,
  setRegistrationVerifiedStudents,
  setRegistrationStudentFirstname,
  setRegistrationStudentSurnname,
} = registerSlice.actions;

export const selectRegistrationEmail = (state) => state.registerModel.email;
export const selectRegistrationPassword = (state) =>
  state.registerModel.password;
export const selectRegistrationSchools = (state) => state.registerModel.schools;
export const selectRegistrationVerifiedStudents = (state) =>
  state.registerModel.verifiedStudents;

export const selectRegistrationStudentFirstname = (state) =>
  state.registerModel.studentFirstname;
export const selectRegistrationStudentSurname = (state) =>
  state.registerModel.studentSurname;

export default registerSlice.reducer;
