import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import styles from "./Collection.module.css";

import ImageCard from "../../components/imageCard/ImageCard";

import ErrorIcon from "@material-ui/icons/ErrorOutline";

import { API } from "aws-amplify";
import { getNamesOfPreviewImages } from "./CollectionFunctions";
import Button from "../../components/button/Button";
import {
  clearPreviewImageObjects,
  selectAvoidSearchLimit,
  selectPreviewImageObjects,
  selectShouldSearchAgain,
  selectUpload,
  selectUploadFileName,
  selectYears,
  setPreviewImageObjects,
  setShouldSearchAgain,
} from "../../app/reducers/UploaderSlice";
import {
  selectEmail,
  selectUsername,
  selectUserSchools,
} from "../../app/reducers/UserSlice";
import { addImageToCart } from "../../app/reducers/CartSlice";
import { override } from "../../utils/LoaderOverride";

const ImageCollection = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [responseError, setResponseError] = useState("");
  const previewImageObjects = useSelector(selectPreviewImageObjects);
  const shouldSearchAgain = useSelector(selectShouldSearchAgain);
  const uploadedImage = useSelector(selectUpload);
  const uploadedFileName = useSelector(selectUploadFileName);
  const username = useSelector(selectUsername);
  const email = useSelector(selectEmail);
  const userSchools = useSelector(selectUserSchools);
  const years = useSelector(selectYears);
  const avoidSearchLimit = useSelector(selectAvoidSearchLimit);

  useEffect(() => {
    if (uploadedImage.length === 0) {
      setIsLoading(false);
      setResponseError("Please upload an image.");
    } else if (shouldSearchAgain) {
      callAPI();
    }
  }, []);

  const callAPI = async () => {
    setIsLoading(true);
    API.post("getImages", "/getImages", {
      body: {
        fileName: uploadedFileName,
        blob: uploadedImage,
        username: username,
        email: email,
        avoidSearchLimit: avoidSearchLimit,
        userSchools: userSchools,
      },
    })
      .then(async (res) => {
        if (res.errorMessage) {
          setIsLoading(false);
          setResponseError(res.errorMessage);
          return;
        }

        if (!res.length) {
          setIsLoading(false);
          setResponseError(
            "No images were returned. Try uploading another image."
          );
          return;
        }

        const { yearsFromResponse, previewImages } =
          await getNamesOfPreviewImages(res, userSchools);

        dispatch(
          setPreviewImageObjects({
            previewImageObjects: previewImages,
            years: yearsFromResponse,
          })
        );

        setIsLoading(false);
      })
      .catch(() => {
        setResponseError("An error occurred. Please try again");
        setIsLoading(false);
      });
    dispatch(setShouldSearchAgain(false));
  };

  const addAllToCart = () => {
    previewImageObjects.forEach((image) => {
      dispatch(
        addImageToCart({
          key: image.lowerResKey,
          presignedUrl: image.presignedUrl,
        })
      );
    });
  };

  if (isLoading) {
    return (
      <div className={styles.collectionContainer}>
        <h2>Your Images</h2>
        <div className={styles.collectionContent}>
          {previewImageObjects.length > 0 && (
            <>
              <p>We will combine these results with your previous search</p>
              <br></br>
            </>
          )}
          <PuffLoader
            css={override}
            size={150}
            color={"#484848"}
            radius={"1em"}
            loading={isLoading}
          />
          <p>This can take some time...</p>
          <p>Please do not close this window</p>
        </div>
      </div>
    );
  } else if (responseError !== "") {
    return (
      <div className={styles.collectionContainer}>
        <h2>Your Images</h2>
        <div className={styles.collectionContent}>
          <div className={styles.errorIconContainer}>
            <ErrorIcon></ErrorIcon>
          </div>
          <p>{responseError}</p>
          <div className={styles.buttonContainer}>
            {responseError !== "You have exceeded the search limit of 3." && (
              <Button
                isRedirect={true}
                isPrimary={true}
                path='/upload'
                buttonText='Upload'
                disabled={false}
              />
            )}
            {responseError === "You have exceeded the search limit of 3." && (
              <a href='mailto:info@oldschoolphotos.co.za?subject=Image upload limit reached'>
                <Button
                  isRedirect={false}
                  buttonText='Contact Us'
                  disabled={false}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  } else if (previewImageObjects.length === 0) {
    return (
      <div className={styles.collectionContainer}>
        <h2>Your Images</h2>
        <div className={styles.collectionContent}>
          <div className={styles.errorIconContainer}>
            <ErrorIcon></ErrorIcon>
          </div>
          <p>There are no images to show</p>
          <p>Try uploading another image</p>
          <div className={styles.buttonContainer}>
            <Button
              isRedirect={true}
              path='/upload'
              buttonText='Upload'
              disabled={false}
            />
          </div>
        </div>
      </div>
    );
  } else {
    const imageCardsPerYear = !years.length
      ? []
      : years.map((year) => {
          return (
            <div key={year} className={styles.yearContainer}>
              <h3>{year}</h3>
              <hr></hr>
              <span className={styles.imageGridContainer}>
                {previewImageObjects
                  ?.filter((imageObject) => imageObject.year === year)
                  ?.map((imageObject, index) => {
                    return (
                      <span key={index} className={styles.image}>
                        <ImageCard
                          imageName={imageObject.name}
                          imageKey={imageObject.lowerResKey}
                          presignedUrl={imageObject.presignedUrl}
                          showAddToCart={true}
                          height={300}
                        />
                      </span>
                    );
                  })}
              </span>
            </div>
          );
        });

    return (
      <div className={styles.collectionContainer}>
        <h2>Your Images</h2>
        <div className={styles.numberImages}>
          <p>Results: {previewImageObjects.length} images</p>
          <p>
            Note: This software is very very accurate - sometimes your results
            will include a photo that looks like you are not in the photo. If
            that is the case look closely, and you may find that you are
            somewhere in the background.
          </p>
        </div>
        <div className={styles.addAllImagesContainer}>
          <div className={styles.buttonContainer}>
            <Button
              path='/checkout'
              isPrimary={true}
              isRedirect={true}
              buttonText='Proceed to checkout'
            ></Button>
            <Button
              onClick={() => addAllToCart()}
              isPrimary={false}
              isRedirect={false}
              buttonText={"Add all images to cart"}
            ></Button>

            <Button
              onClick={() => dispatch(clearPreviewImageObjects())}
              isPrimary={false}
              isRedirect={false}
              buttonText={"Clear search results"}
            ></Button>
          </div>
        </div>
        <span>{imageCardsPerYear}</span>
        <h5 className={styles.numberImages}>
          For images images taken after 2013, contact That's Me Photography -{" "}
          <a href='mailto:info@thatsme.co.za?subject=Access to Photos After 2013'>
            info@thatsme.co.za
          </a>
        </h5>
      </div>
    );
  }
};

export default ImageCollection;
