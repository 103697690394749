import React from "react";
import { Provider } from "react-redux";
import Home from "../pages/Home/Home";
import ImageCollection from "../pages/ImageCollection/ImageCollection";
import Login from "../pages/Login/Login";

import { BrowserRouter as Router, Switch } from "react-router-dom";

import Upload from "../pages/Upload/Upload";
import NavigationBar from "../components/navigationBar/NavigationBar";
import ResetPassword from "../pages/Login/ResetPassword";
import ConfirmRegistrationCode from "../pages/Login/ConfirmRegistrationCode";
import NotFound from "../pages/NotFound/NotFound";
import Checkout from "../pages/Checkout/Checkout";
import styles from "./root.module.css";

import AuthenticatedRoute from "./routes/AuthenticatedRoutes";
import UnauthenticatedRoute from "./routes/UnauthenticatedRoutes";
import SideBarCart from "../components/sideBarCart/SideBarCart";
import History from "../pages/History/History";
import Register from "../pages/Register/Register";
import Profile from "../pages/Profile/Profile";
import Admin from "../pages/Admin/Admin";
import TemporaryPasswordLogin from "../pages/Login/TemporaryPasswordLogin";
import BottomCartDetails from "../components/bottomCartDetails/BottomCartDetails";
import AboutUs from "../pages/AboutUs/AboutUs";
import PrivacyPolicy from "../pages/AboutUs/PrivacyPolicy";

export const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <div>
        <div className={styles.navContainer}>
          <NavigationBar />
          <SideBarCart />
          <BottomCartDetails />
        </div>
        <div id='rootContainer' className={styles.screenContainer}>
          <Switch>
            <UnauthenticatedRoute exact path='/' component={Home} />
            <UnauthenticatedRoute exact path='/about-us' component={AboutUs} />

            <UnauthenticatedRoute
              exact
              path='/privacy-policy'
              component={PrivacyPolicy}
            />

            <AuthenticatedRoute exact path='/upload' component={Upload} />
            <AuthenticatedRoute
              exact
              path='/images'
              component={ImageCollection}
            />
            <AuthenticatedRoute exact path='/checkout' component={Checkout} />
            <AuthenticatedRoute exact path='/orders' component={History} />
            <AuthenticatedRoute exact path='/profile' component={Profile} />
            <AuthenticatedRoute exact path='/admin' component={Admin} />

            <UnauthenticatedRoute exact path='/register' component={Register} />
            <UnauthenticatedRoute
              exact
              path='/confirm_registration'
              component={ConfirmRegistrationCode}
            />
            <UnauthenticatedRoute
              exact
              path='/resetpassword'
              component={ResetPassword}
            />
            <UnauthenticatedRoute exact path='/login' component={Login} />
            <UnauthenticatedRoute
              exact
              path='/temporary-login'
              component={TemporaryPasswordLogin}
            />

            <UnauthenticatedRoute component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  </Provider>
);

export default Root;
