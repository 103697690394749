import React from "react";
import styles from "./ImageCardModal.module.css";
import Close from "@material-ui/icons/Close";

export const ImageCardModal = ({ imgSrc, closeModal }) => {
  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modalCard}>
        <span className={styles.removeIcon} onClick={() => closeModal()}>
          <Close style={{ fontSize: "30px" }} />
        </span>
        <img alt='Card popup' src={imgSrc}></img>
      </div>
    </div>
  );
};
