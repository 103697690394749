import React from "react";
import { useSelector } from "react-redux";
import {
  selectEmail,
  selectFullname,
  selectUserSchools,
} from "../../app/reducers/UserSlice";
import styles from "./Profile.module.css";
import PastSearchImages from "../../components/pastSearchImages/PastSearchImages";

const Profile = () => {
  const fullName = useSelector(selectFullname);
  const email = useSelector(selectEmail);

  const schools = useSelector(selectUserSchools);

  return (
    <div className={styles.profileContainer}>
      <h2 className={styles.headingContainer}>My Profile</h2>
      <div className={styles.contentContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsRow}>
            <p>
              <b>Name:</b>
            </p>
            <p>{fullName}</p>
          </div>
          <hr></hr>
          <div className={styles.detailsRow}>
            <p>
              <b>Email: </b>
            </p>
            <p>{email}</p>
          </div>
          <hr></hr>
          <div className={styles.detailsRow}>
            <p>
              <b>School(s): </b>
            </p>
            <div>
              {schools?.map((school) => (
                <div className={styles.schoolName} key={school}>
                  {school}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr></hr>
        <div className={styles.pastSearchWrapper}>
          <PastSearchImages></PastSearchImages>
        </div>
      </div>
    </div>
  );
};

export default Profile;
