import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";
import PuffLoader from "react-spinners/PuffLoader";
import { override } from "../../utils/LoaderOverride";

import styles from "./Admin.module.css";
import Button from "../../components/button/Button";
import InputField from "../../components/inputField/InputField";
import DropdownList from "../../components/dropdownList/DropdownList";

const Students = ({ goBackToList }) => {
  const username = useSelector(selectUsername);

  const [studentError, setStudentError] = useState("");
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [students, setStudents] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [school, setSchool] = useState("");

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = () => {
    setIsLoadingStudents(true);
    setStudentError("");

    API.post("getStudentList", "/getStudentList", {
      body: {
        username: username,
      },
    })
      .then((res) => {
        setStudents(res.students.Items);
        setIsLoadingStudents(false);
      })
      .catch((err) => {
        setStudentError(err);
        setIsLoadingStudents(false);
      });
  };

  const addNewEntry = () => {
    API.post("addStudentToDB", "/addStudentToDB", {
      body: {
        username: username,
        firstName: firstName,
        surname: surname,
        school: school,
      },
    })
      .then(() => {
        window.alert("Success");
      })
      .catch((err) => {
        window.alert("Failed", err);
      });
  };

  let listOfAvailableSchools = [
    "Reddam Atlantic Seaboard",
    "Reddam Constantia",
    "Micklefield",
  ];

  const dropdownItems = listOfAvailableSchools.map((schoolName) => {
    return { value: schoolName, label: schoolName };
  });

  const setSchoolDetails = (name) => {
    setSchool(name);
  };

  const goBack = () => {
    goBackToList();
  };

  return (
    <div className={styles.AdminContainer}>
      <h2 className={styles.headingContainer}>Student database</h2>
      <div className={styles.buttonContainer}>
        <InputField
          name='firstname'
          type='text'
          labelText='Firstname'
          value={firstName}
          handleChange={(event) => setFirstName(event.target.value)}
        />
        <InputField
          name='surname'
          type='text'
          labelText='Surname'
          value={surname}
          handleChange={(event) => setSurname(event.target.value)}
        />
        <DropdownList
          id='school'
          label='Select a school'
          dropdownOptions={dropdownItems}
          value={school}
          defaultText='Select a school'
          onSelect={(e) => setSchoolDetails(e.target.value)}
        />
        <Button
          onClick={() => addNewEntry()}
          isRedirect={false}
          disabled={!school || !firstName || !surname}
          buttonText='Add new entry'
          isPrimary={true}
        />
        <Button
          onClick={() => goBack()}
          isRedirect={false}
          buttonText='Back'
          isPrimary={false}
        />
      </div>

      <div className={styles.contentContainer}>
        {isLoadingStudents ? (
          <div className={styles.searchesErrorContainer}>
            <PuffLoader
              css={override}
              size={100}
              color={"#484848"}
              radius={"0.5em"}
              loading={true}
            />
            <p>Loading students...</p>
          </div>
        ) : studentError ? (
          <div className={styles.searchesErrorContainer}>
            <p>{studentError}</p>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Full name</th>
                <th>School</th>
                <th>Registered?</th>
                <th>Registered Email</th>
              </tr>
            </thead>
            <tbody>
              {students?.map((student) => {
                return (
                  <tr>
                    <td>{student.fullname}</td>
                    <td>{student.school}</td>
                    <td>{student.registered.toString()}</td>
                    <td>{student.registeredEmailAddress}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Students;
