import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Login.module.css";
import PuffLoader from "react-spinners/PuffLoader";

import {
  setUserAuthentication,
  setEmail,
  setUserSchools,
  selectIsAuthenticated,
  selectHasSubmittedLogin,
  selectLoginErrorMessage,
  selectEmail,
  selectIsLoggingIn,
  setIsLoggingIn,
  setLoginSubmitted,
  setLoginErrorMessage,
  setUsername,
  setFullname,
  setIsSuperUser,
} from "../../app/reducers/UserSlice";

import { Auth } from "aws-amplify";

import InputField from "../../components/inputField/InputField";
import Button from "../../components/button/Button";
import { override } from "../../utils/LoaderOverride";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const submitted = useSelector(selectHasSubmittedLogin);
  const loginErrorMsg = useSelector(selectLoginErrorMessage);
  const email = useSelector(selectEmail);
  const isLoggingIn = useSelector(selectIsLoggingIn);

  const [isAutoLoggingIn, setIsAutoLoggingIn] = useState(false);
  const [password, setPassword] = useState("");

  const { pathname, search } = useLocation();

  useEffect(() => {
    dispatch(setIsLoggingIn(false));
    dispatch(setLoginSubmitted(false));
    dispatch(setLoginErrorMessage(""));
  }, []);

  useEffect(() => {
    (async function getLoginDetails() {
      try {
        setIsAutoLoggingIn(true);
        const userSession = await Auth.currentSession();
        const userObj = await Auth.currentUserInfo();

        dispatch(setIsSuperUser(userSession?.getIdToken()?.payload));
        dispatch(setUserAuthentication(true));
        dispatch(setUsername(userObj?.username));
        dispatch(
          setUserSchools(userObj?.attributes["custom:School1"]?.split(","))
        );
        dispatch(setEmail(userSession.getIdToken()?.payload.email));
        dispatch(setFullname(userObj?.attributes["custom:fullname"]));

        if (search.startsWith("?redirect=")) {
          const redirectRoute = search.substr(10, search.length);
          setTimeout(() => {
            history.push(redirectRoute);
          }, 1000);
        } else {
          setTimeout(() => {
            history.push("/");
          }, 1000);
        }
      } catch (e) {
        setIsAutoLoggingIn(false);
        dispatch(setUserAuthentication(false));
      }
    })();
  }, []);

  const handleSubmit = async () => {
    dispatch(setIsLoggingIn(true));
    dispatch(setLoginSubmitted(true));
    dispatch(setLoginErrorMessage(""));

    try {
      const userObj = await Auth.signIn(email, password);
      const userSession = await Auth.currentSession();

      dispatch(setIsSuperUser(userSession.getIdToken()?.payload));
      dispatch(setUserAuthentication(true));
      dispatch(setUsername(userObj?.username));
      dispatch(setEmail(email));
      dispatch(
        setUserSchools(userObj?.attributes["custom:School1"]?.split(","))
      );
      dispatch(setFullname(userObj?.attributes["custom:fullname"]));
      dispatch(setIsLoggingIn(false));

      if (search.startsWith("?redirect=")) {
        const redirectRoute = search.substr(10, search.length);
        setTimeout(() => {
          history.push(redirectRoute);
        }, 1000);
      } else {
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    } catch (error) {
      dispatch(setIsLoggingIn(false));

      if (
        process.env.REACT_APP_IS_DEV === "true" ||
        error.message === "Incorrect username or password."
      ) {
        dispatch(setLoginErrorMessage(error.message));
      } else {
        dispatch(
          setLoginErrorMessage("Something went wrong. Please try again")
        );
      }
    }
  };

  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  };

  return (
    <div className={styles.loginContainer}>
      {isAutoLoggingIn ? (
        <div className={styles.logginInContainer}>
          <div>
            <PuffLoader
              css={override}
              size={150}
              color={"#484848"}
              radius={"1em"}
              loading={true}
            />
            <p className={styles.loggingYouIn}>Logging you in</p>
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.headerContainer}>
            <h2 className={styles.headingFont}>Login</h2>
          </div>
          <InputField
            name='email'
            type='email'
            labelText='Email Address'
            value={email}
            handleChange={(event) => dispatch(setEmail(event.target.value))}
            showError={submitted && !email}
            errorMessage='Email Address is required'
          />

          <InputField
            name='password'
            type='password'
            labelText='Password'
            value={password}
            handleChange={(event) => setPassword(event.target.value)}
            showError={submitted && !password}
            errorMessage='Password is required'
            isPasswordField={true}
          />

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => handleSubmit()}
              isRedirect={false}
              disabled={!validateForm()}
              buttonText='Login'
              isPrimary={true}
            />
            <Button buttonText='Register' isRedirect={true} path='/register' />
            <Button
              buttonText='Forgot Password?'
              isRedirect={true}
              path='/resetpassword'
            />
            <div className={styles.loaderContainer}>
              {isLoggingIn && (
                <img
                  alt='loading'
                  className={styles.loader}
                  src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                />
              )}
              {!isAuthenticated && submitted && !isLoggingIn && (
                <img alt='cross' src='x-sym.jpg' className={styles.ximg} />
              )}
              {isAuthenticated && (
                <img alt='tick' src='tick.png' className={styles.ximg} />
              )}

              <div className={styles.loginErrorMessage}>
                {!isLoggingIn && loginErrorMsg}
              </div>
              {!isLoggingIn && loginErrorMsg === "User is not confirmed." && (
                <Button
                  buttonText='Resend or Confirm Code?'
                  isRedirect={true}
                  path='/confirm_registration'
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
