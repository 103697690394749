import { createSlice } from "@reduxjs/toolkit";
import { getPrice, getPricePerImage } from "../../utils/price";

export const cartSlice = createSlice({
  name: "cartModel",
  initialState: {
    imagesInCart: [],
    isSideCartOpen: false,
    hasOpenedCartBefore: false,
  },
  reducers: {
    clearCart: (state, _) => {
      state.imagesInCart = [];
    },
    addImageToCart: (state, action) => {
      if (state.imagesInCart.length === 0) {
        state.hasOpenedCartBefore = true;
      }

      if (
        state.imagesInCart.filter(
          (cartItem) => cartItem.key === action.payload.key
        ).length === 0
      ) {
        state.imagesInCart.push(action.payload);
      }
    },
    removeImageFromCart: (state, action) => {
      state.imagesInCart = state.imagesInCart.filter(
        (img) => img.key !== action.payload
      );
    },
    toggleSideCart: (state) => {
      state.isSideCartOpen = !state.isSideCartOpen;
    },
  },
});

export const {
  clearCart,
  addImageToCart,
  removeImageFromCart,
  toggleSideCart,
} = cartSlice.actions;

export const selectIsSideCartOpen = (state) => state.cartModel.isSideCartOpen;
export const selectNumberItemsInCart = (state) =>
  state.cartModel.imagesInCart.length;
export const selectImagesInCart = (state) => state.cartModel.imagesInCart;
export const selectHasOpenedCartBefore = (state) =>
  state.cartModel.hasOpenedCartBefore;

export const selectNamesOfImagesForDb = (state) => {
  return state.cartModel.imagesInCart.map((image) => image.key).join(";");
};

export const selectPriceOfCart = (state) => {
  const numItems = state.cartModel.imagesInCart.length;

  return getPrice(numItems);
};

export const selectCurrentImagePrice = (state) => {
  const numItems = state.cartModel.imagesInCart.length;

  return getPricePerImage(numItems);
};

export default cartSlice.reducer;
