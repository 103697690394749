import React from "react";
import { Link } from "react-router-dom";
import styles from "./Button.module.css";
import PuffLoader from "react-spinners/PuffLoader";
import { override } from "../../utils/LoaderOverride";

const Button = ({
  buttonText,
  onClick,
  isRedirect,
  path,
  disabled,
  isPrimary,
  isLoading,
}) => {
  const primaryStyling = isPrimary ? `${styles.primaryButton}` : "";
  const disabledStyling = disabled ? `${styles.disabled}` : "";

  const onClickEvent = () => {
    if (!onClick) {
      return;
    }
    onClick();
  };

  if (isLoading) {
    return (
      <div className={styles.buttonContainer}>
        <div
          className={`${styles.button} ${primaryStyling} ${disabledStyling} `}
        >
          <PuffLoader
            css={override}
            size={25}
            color={"#000"}
            loading={isLoading}
          />
        </div>
      </div>
    );
  } else if (isRedirect) {
    return (
      <div className={styles.buttonContainer}>
        <Link
          disabled={disabled}
          to={path}
          onClick={() => onClickEvent()}
          className={`${styles.button} ${primaryStyling} ${disabledStyling} `}
        >
          {buttonText}
        </Link>
      </div>
    );
  } else {
    return (
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${primaryStyling}`}
          onClick={() => onClickEvent()}
          disabled={disabled}
        >
          {buttonText}
        </button>
      </div>
    );
  }
};
export default Button;
