import { createSlice } from "@reduxjs/toolkit";

export const uploaderSlice = createSlice({
  name: "uploader",
  initialState: {
    img: [],
    previewImageObjects: [],
    years: [],
    fileName: "",
    avoidSearchLimit: false,
    shouldSearchAgain: false,
  },
  reducers: {
    setUpload: (state, action) => {
      state.img = action.payload;
    },
    setUploadFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setPreviewImageObjects: (state, action) => {
      const newPreviewImageObjects = [...state.previewImageObjects];

      action.payload.previewImageObjects.forEach((incomingObject) => {
        const existingObject = state.previewImageObjects.filter(
          (stateObject) => stateObject.key === incomingObject.key
        );
        if (!existingObject.length) {
          newPreviewImageObjects.push(incomingObject);
        }
      });

      state.previewImageObjects = newPreviewImageObjects;

      state.years = [...new Set([...state.years, ...action.payload.years])];
    },

    clearPreviewImageObjects: (state, _) => {
      state.previewImageObjects = [];
      state.years = [];
    },

    setAvoidSearchLimit: (state, action) => {
      state.avoidSearchLimit = action.payload;
    },

    setShouldSearchAgain: (state, action) => {
      state.shouldSearchAgain = action.payload;
    },
  },
});

export const {
  setUpload,
  setUploadFileName,
  clearPreviewImageObjects,
  setPreviewImageObjects,
  setAvoidSearchLimit,
  setShouldSearchAgain,
} = uploaderSlice.actions;

export const selectUpload = (state) => state.uploader.img;
export const selectPreviewImageObjects = (state) =>
  state.uploader.previewImageObjects;
export const selectYears = (state) => {
  if (state.uploader.years.length) {
    return state.uploader.years.slice().sort((item1, item2) => item1 - item2);
  }
  return [];
};
export const selectUploadFileName = (state) => state.uploader.fileName;
export const selectShouldSearchAgain = (state) =>
  state.uploader.shouldSearchAgain;
export const selectAvoidSearchLimit = (state) =>
  state.uploader.avoidSearchLimit;

export default uploaderSlice.reducer;
