import React from "react";
import styles from "./Login.module.css";
import { Link } from "react-router-dom";

import { Auth } from "aws-amplify";
import InputField from "../../components/inputField/InputField";
import Button from "../../components/button/Button";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      codeSent: false,
      confirmed: false,
      isSending: false,
      isConfirming: false,

      errorMessage: "",

      email: "",
      password: "",
      confirmPassword: "",
      code: "",
    };

    this.handleConfirmSubmit = this.handleConfirmSubmit.bind(this);
    this.handleSendCodeSubmit = this.handleSendCodeSubmit.bind(this);
    this.handleConfirmClick = this.handleConfirmClick.bind(this);
    this.validateResetForm = this.validateResetForm.bind(this);
    this.validateSendCodeForm = this.validateSendCodeForm.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  validateSendCodeForm() {
    return this.state.email.length > 0;
  }

  passwordErrorMessage() {
    const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    if (this.state.password !== "") {
      if (!regex.test(this.state.password)) {
        return "Required: 6 characters, one uppercase and lowercase letter and a number.";
      }
    }

    return "";
  }

  confirmPasswordErrorMessage() {
    if (this.state.password !== "") {
      if (this.state.isConfirming && !this.state.confirmPassword) {
        return "Password is required";
      } else if (this.state.confirmPassword !== this.state.password) {
        return "Passwords do not match.";
      }
    }
    return "";
  }

  validateResetForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length >= 6 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleSendCodeSubmit(e) {
    this.setState({ isSending: true, errorMessage: "" });
    const { email } = this.state;

    Auth.forgotPassword(email)
      .then((res) => {
        this.setState({ codeSent: true, isSending: false });
      })
      .catch((e) => {
        this.setState({ isSending: false, errorMessage: e.message });
      });
  }

  handleConfirmSubmit(e) {
    this.setState({ isConfirming: true, errorMessage: "" });
    const { email, code, password } = this.state;

    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        this.setState({ confirmed: true, isConfirming: false });
      })
      .catch((e) => {
        this.setState({ isConfirming: false, errorMessage: e.message });
      });
  }

  handleConfirmClick() {
    this.setState({ codeSent: true, isSending: false });
  }

  render() {
    const {
      errorMessage,
      email,
      password,
      confirmPassword,
      codeSent,
      confirmed,
      code,
      isSending,
      isConfirming,
    } = this.state;

    const renderRequestCodeForm = (
      <div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Forgot Password</h2>
        </div>
        <p className={styles.loginErrorMessage}>
          We will send a verification code to your email address.
        </p>
        <InputField
          name='email'
          type='email'
          labelText='Email Address'
          value={email}
          handleChange={(event) =>
            this.setState({ ...this.state, email: event.target.value })
          }
        />
        <div class={styles.buttonContainer}>
          <Button
            onClick={() => this.handleSendCodeSubmit()}
            isRedirect={false}
            disabled={!this.validateSendCodeForm()}
            buttonText='Send code'
            isPrimary={true}
          />
          <Button
            onClick={() => this.handleConfirmClick()}
            isRedirect={false}
            buttonText='Confirm code'
            isPrimary={false}
          />

          {isSending && (
            <div className={styles.loaderContainer}>
              <img
                alt='loading'
                class={styles.loader}
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            </div>
          )}
          <div>{errorMessage}</div>
        </div>
      </div>
    );

    const renderConfirmationForm = (
      <div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Confirm Code</h2>
        </div>
        <InputField
          name='code'
          type='text'
          labelText='Verification Code'
          value={code}
          handleChange={(event) =>
            this.setState({ ...this.state, code: event.target.value })
          }
        />
        <InputField
          name='email'
          type='email'
          labelText='Email Address'
          value={email}
          handleChange={(event) =>
            this.setState({ ...this.state, email: event.target.value })
          }
        />
        <InputField
          name='password'
          type='password'
          labelText='New Password'
          isPasswordField={true}
          value={password}
          handleChange={(event) =>
            this.setState({ ...this.state, password: event.target.value })
          }
          showError={this.passwordErrorMessage().length > 0}
          errorMessage={this.passwordErrorMessage()}
        />
        <InputField
          name='confirmPassword'
          type='password'
          labelText='Confirm password'
          isPasswordField={true}
          value={confirmPassword}
          handleChange={(event) =>
            this.setState({
              ...this.state,
              confirmPassword: event.target.value,
            })
          }
          showError={this.confirmPasswordErrorMessage().length > 0}
          errorMessage={this.confirmPasswordErrorMessage()}
        />

        <div class={styles.buttonContainer}>
          <Button
            onClick={() => this.handleConfirmSubmit()}
            isRedirect={false}
            disabled={!this.validateResetForm()}
            buttonText='Change password'
            isPrimary={true}
          />
          <Button
            onClick={() => this.setState({ codeSent: false })}
            isRedirect={false}
            buttonText='Resend Code'
            isPrimary={false}
          />
          <p className={styles.loginErrorMessage}>
            A verification code has been sent to your email address. Check your
            spam/junk folder if you can't find the mail.
            <br></br>
            If you did not receive your code, please double check you entered
            the correct email address or email us here:{" "}
            <a href='mailto:info@oldschoolphotos.co.za?subject=Missing Verification Code'>
              info@oldschoolphotos.co.za
            </a>
          </p>
          {(isConfirming || isSending) && (
            <div className={styles.loaderContainer}>
              <img
                alt='loading'
                class={styles.loader}
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            </div>
          )}
          <div>{errorMessage}</div>
        </div>
      </div>
    );

    const renderSuccessMessage = (
      <div className='success'>
        <p>Your password has been reset.</p>
        <p>
          <Link to='/login'>
            Click here to login with your new credentials.
          </Link>
        </p>
      </div>
    );

    return (
      <div className={styles.loginContainer}>
        {!codeSent
          ? renderRequestCodeForm
          : !confirmed
          ? renderConfirmationForm
          : renderSuccessMessage}
      </div>
    );
  }
}

export default ResetPassword;
