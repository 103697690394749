import { configureStore } from "@reduxjs/toolkit";
import imageReducer from "./reducers/UploaderSlice";
import userReducer from "./reducers/UserSlice";
import cartReducer from "./reducers/CartSlice";
import checkoutReducer from "./reducers/CheckoutSlice";
import registerReducer from "./reducers/RegisterSlice";

export default configureStore({
  reducer: {
    uploader: imageReducer,
    userModel: userReducer,
    cartModel: cartReducer,
    checkoutModel: checkoutReducer,
    registerModel: registerReducer,
  },
});
