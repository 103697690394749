import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./History.module.css";
import { API } from "aws-amplify";
import { API_STATUS } from "../../const/Constants";
import PuffLoader from "react-spinners/PuffLoader";

import ArrowDropDownCircle from "@material-ui/icons/ArrowDropDownCircle";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Retry from "@material-ui/icons/Replay";
import Basket from "@material-ui/icons/CreditCard";

import Done from "@material-ui/icons/Done";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { setPaymentId } from "../../app/reducers/CheckoutSlice";

import JSZip from "jszip";

import saveAs from "file-saver";

import {
  selectPreviousTransactionsApiStatus,
  selectPreviousTransactionsData,
  selectUsername,
  setPreviousTransactionsApiStatus,
  setPreviousTransactionsData,
  setPreviousTransactionsDownloadingStatusAtIndex,
} from "../../app/reducers/UserSlice";
import ImageCard from "../../components/imageCard/ImageCard";
import { addImageToCart, clearCart } from "../../app/reducers/CartSlice";
import Button from "../../components/button/Button";
import { useHistory } from "react-router";
import { logBugsnagError } from "../../utils/Bugsnag";
import DownloadModal from "./DownloadModal";
import { override } from "../../utils/LoaderOverride";

const History = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const username = useSelector(selectUsername);
  const previousTransactions = useSelector(selectPreviousTransactionsData);
  const previousTransactionsApiStatus = useSelector(
    selectPreviousTransactionsApiStatus
  );

  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0.1);
  const [numberOfImagesDownloaded, setNumberOfImagesDownloaded] = useState(0);
  const [totalNumberToDownload, setTotalNumberToDownload] = useState(0);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getPurchasedImages();
  };

  const getPurchasedImages = () => {
    if (
      previousTransactionsApiStatus === API_STATUS.IDLE ||
      previousTransactionsApiStatus === API_STATUS.ERROR
    ) {
      dispatch(setPreviousTransactionsApiStatus(API_STATUS.PENDING));
      const reqObj = { username: username };
      API.post("getPurchasedImages", "/getPurchasedImages", {
        body: reqObj,
      })
        .then((res) => {
          dispatch(setPreviousTransactionsApiStatus(API_STATUS.SUCCESS));
          dispatch(setPreviousTransactionsData(res.transactions));
        })
        .catch((err) => {
          dispatch(setPreviousTransactionsApiStatus(API_STATUS.ERROR));
        });
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const downloadAllFiles = async (e, transaction) => {
    if (isDownloading) return;

    e.preventDefault();

    setIsDownloading(true);
    setNumberOfImagesDownloaded(0);
    setTotalNumberToDownload(transaction.images.length);

    const transactionIndex = previousTransactions.indexOf(transaction);

    let zip = new JSZip();

    setIsDownloadModalOpen(true);
    setDownloadProgress(0.1);
    dispatch(
      setPreviousTransactionsDownloadingStatusAtIndex({
        isDownloading: true,
        downloadProgress: 0.1,
        transactionIndex: transactionIndex,
      })
    );

    let numberImagesDownload = 0;
    for (let image of transaction.images) {
      const res = await API.post("getImageObject", "/getImageObject", {
        body: { key: image.key },
      });

      const presignedRes = await fetch(res.presignedUrl);
      const blob = await presignedRes.blob();
      let base64String = await blobToBase64(blob);
      base64String = base64String.split(",")[1];

      const splitName = image.key.split("/");
      const newImageKey = splitName[1] + "/" + splitName[splitName.length - 1];

      zip.file(newImageKey, base64String, { base64: true });

      numberImagesDownload += 1;
      const currentDownloadProgress =
        (numberImagesDownload * 100) / transaction.images.length;
      dispatch(
        setPreviousTransactionsDownloadingStatusAtIndex({
          downloadProgress: currentDownloadProgress,
          isDownloading: true,
          isDownloaded: numberImagesDownload === transaction.images.length,
          transactionIndex: transactionIndex,
        })
      );
      setNumberOfImagesDownloaded(numberImagesDownload);
      setDownloadProgress(currentDownloadProgress);
    }
    try {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "PastSchoolImages.zip");
      });
    } catch (error) {
      logBugsnagError(error);
    }
    setIsDownloading(false);
    setIsDownloadModalOpen(false);
  };

  const dateToString = (date) => {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      date.getFullYear(),
    ].join("/");
  };

  const continuePendingOrder = async (transaction) => {
    dispatch(clearCart());
    dispatch(setPaymentId(transaction.paymentId));

    await transaction.images.forEach((image) => {
      const lowerResKey = image.key.replace(/High_Res/g, "Low_Res");
      dispatch(
        addImageToCart({ key: lowerResKey, presignedUrl: image.presignedUrl })
      );
    });

    history.push("/checkout");
  };

  return (
    <div className={styles.collectionContainer}>
      {isDownloadModalOpen && (
        <DownloadModal
          downloadProgress={downloadProgress}
          numberOfImagesDownloaded={numberOfImagesDownloaded}
          totalNumberToDownload={totalNumberToDownload}
          closeModal={() => setIsDownloadModalOpen(false)}
        />
      )}
      <h2 className={styles.headingContainer}>My Orders</h2>
      <h6 className={styles.hintText}>
        If your purchase is not showing up, try refreshing the page.
      </h6>
      {previousTransactionsApiStatus === API_STATUS.PENDING && (
        <div className={styles.errorContainer}>
          <PuffLoader
            css={override}
            size={150}
            color={"#484848"}
            radius={"1em"}
            loading={true}
          />
        </div>
      )}
      {previousTransactionsApiStatus === API_STATUS.ERROR && (
        <div className={styles.errorContainer}>
          <div className={styles.retryIcon}>
            <Retry onClick={() => init()} />
          </div>
          <div>An error has occurred, please try again.</div>
        </div>
      )}
      {previousTransactionsApiStatus === API_STATUS.SUCCESS &&
        previousTransactions.length == 0 && (
          <div className={styles.errorContainer}>
            <Basket className={styles.emptyIcon}></Basket>
            <div>You have not purchased any images yet.</div>
            <div className={styles.buttonContainer}>
              <Button
                isRedirect={true}
                path='/upload'
                buttonText='Upload'
                isPrimary={true}
                disabled={false}
              />
            </div>
          </div>
        )}
      {previousTransactionsApiStatus === API_STATUS.SUCCESS &&
        previousTransactions.length > 0 && (
          <div className={styles.imagesContainer}>
            <h6 className={styles.hintText}>
              Note: there will be no watermark on the photos when they are
              downloaded
            </h6>
            {isDownloading && (
              <div className={styles.downloadProgressButtonContainer}>
                <Button
                  isRedirect={false}
                  buttonText='Show download progress'
                  isPrimary={true}
                  disabled={false}
                  onClick={() => setIsDownloadModalOpen(true)}
                />
              </div>
            )}
            {previousTransactions
              .filter(
                (transaction) =>
                  transaction.paymentStatus !== "CANCELLED" &&
                  transaction.paymentStatus !== "INVESTIGATE"
              )
              .sort(
                (transaction1, transaction2) =>
                  new Date(parseInt(transaction2.date)) -
                  new Date(parseInt(transaction1.date))
              )
              .map((transaction) => {
                const date =
                  typeof transaction.date == "string" &&
                  new Date(parseInt(transaction.date));
                return (
                  <details
                    key={transaction.orderNumber}
                    className={styles.transactionBlock}
                  >
                    <summary>
                      <div className={styles.leftColumn}>
                        <div>
                          <b>#{transaction.orderNumber}</b>
                        </div>
                        <div>Date of order: {date && dateToString(date)}</div>
                        <div>Price: R{transaction.amountPaid}</div>
                        <div>Quantity: {transaction.images.length}</div>
                        <div>Order status: {transaction.paymentStatus}</div>
                        <div className={styles.clickToPreviewText}>
                          Click to preview
                        </div>

                        {transaction.isDownloading &&
                          !transaction.isDownloaded && (
                            <div className={styles.dontCloseText}>
                              Please do not close this tab while downloading
                            </div>
                          )}
                      </div>
                      <div className={styles.rightColumn}>
                        <div className={styles.downloadAllContainer}>
                          <ArrowDropDownCircle className={styles.arrowIcon} />
                          <div className={styles.clickToPreviewText}>
                            Show images
                          </div>
                        </div>
                        {transaction.paymentStatus !== "IN PROGRESS" && (
                          <div className={styles.downloadAllContainer}>
                            {!transaction.isDownloaded &&
                              !transaction.isDownloading && (
                                <div
                                  onClick={(event) =>
                                    downloadAllFiles(event, transaction)
                                  }
                                >
                                  <DownloadIcon />
                                  <div className={styles.clickToPreviewText}>
                                    {isDownloading
                                      ? "Wait for other download"
                                      : "Download all"}
                                  </div>
                                </div>
                              )}

                            {transaction.isDownloading &&
                              !transaction.isDownloaded && (
                                <>
                                  <div className={styles.progressCircle}>
                                    <CircularProgressbar
                                      value={transaction.downloadProgress}
                                      styles={buildStyles({
                                        pathColor: "#484848",
                                      })}
                                    />
                                  </div>
                                  <div className={styles.clickToPreviewText}>
                                    Downloading
                                  </div>
                                </>
                              )}

                            {transaction.isDownloaded && <Done />}
                          </div>
                        )}
                      </div>
                    </summary>

                    <div className={styles.orderBlock}>
                      {transaction.images.map((image) => {
                        return (
                          <div key={image.key} className={styles.imageCard}>
                            <ImageCard
                              imageName={image.key}
                              imageKey={image.key}
                              presignedUrl={image.presignedUrl}
                              showAddToCart={false}
                              downloadable={
                                transaction.paymentStatus !== "IN PROGRESS"
                              }
                              height={window.innerWidth > 900 ? 200 : 150}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {transaction.paymentStatus === "IN PROGRESS" && (
                      <div className={styles.continueOrderBlock}>
                        <div className={styles.continueOrderContainer}>
                          Please complete the PayFast payment or click below to
                          restart the payment Process
                          <Button
                            onClick={() => continuePendingOrder(transaction)}
                            isRedirect={false}
                            buttonText='Continue order'
                            isPrimary={true}
                          />
                        </div>
                      </div>
                    )}
                  </details>
                );
              })}
          </div>
        )}
    </div>
  );
};

export default History;
