import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./NavBar.module.css";
import {
  logout,
  selectIsAuthenticated,
  selectIsSuperUser,
} from "../../app/reducers/UserSlice";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import BackupIcon from "@material-ui/icons/Backup";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PaymentIcon from "@material-ui/icons/Payment";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import LoginIcon from "@material-ui/icons/Input";
import RegisterIcon from "@material-ui/icons/HowToReg";
import People from "@material-ui/icons/People";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Admin from "@material-ui/icons/SupervisedUserCircle";

import Image from "@material-ui/icons/Image";

import { selectPreviewImageObjects } from "../../app/reducers/UploaderSlice";
import ReferAFriendModal from "../referAFriendModal/ReferAFriendModal";

const NavigationBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const previewImageObjects = useSelector(selectPreviewImageObjects);
  const isSuperUser = useSelector(selectIsSuperUser);

  const [isReferModalOpen, setIsReferModalOpen] = useState(false);

  const handleLogout = async () => {
    await Auth.signOut();
    dispatch(logout());
    history.push("/login");
  };

  const expandNav = () => {
    const menu = document.getElementById("nav-contents");
    menu.style.width = "50%";

    const emptyContainer = document.getElementById("empty-container");
    emptyContainer.setAttribute("class", `${styles.emptyContainerVisible}`);
  };

  const closeNav = () => {
    if (window.innerWidth < 600) {
      const menu = document.getElementById("nav-contents");
      menu.style.width = "0";

      const emptyContainer = document.getElementById("empty-container");
      emptyContainer.setAttribute("class", `${styles.emptyContainer}`);
    }
  };

  window.addEventListener("resize", () => {
    if (window.innerWidth > 600) {
      const menu = document.getElementById("nav-contents");
      menu.style.width = "100%";
    }
  });
  return (
    <div>
      <div className={styles.optionsIcon}>
        <MenuIcon onClick={() => expandNav()} />
      </div>
      <div id='nav-contents' className={styles.navBarContainer}>
        <div className={styles.mobileLogo}>
          <img
            alt='logo'
            src='/OldSchoolPhotosLogo.png'
            className={styles.logo}
          />
        </div>
        <ul>
          <li>
            <NavLink
              to='/'
              exact={true}
              className={styles.font}
              onClick={() => closeNav()}
              activeClassName={styles.activatedLink}
            >
              <div className={styles.linkContainer}>
                <HomeIcon className={styles.navBarIcon} />
                Home
              </div>
            </NavLink>
          </li>
          {!isAuthenticated && (
            <>
              <li>
                <NavLink
                  to='/login'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <LoginIcon className={styles.navBarIcon} />
                    Login
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/register'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <RegisterIcon className={styles.navBarIcon} />
                    Register
                  </div>
                </NavLink>
              </li>
            </>
          )}

          {isAuthenticated && (
            <>
              {isSuperUser && (
                <li>
                  <NavLink
                    to='/admin'
                    className={styles.font}
                    onClick={() => closeNav()}
                    activeClassName={styles.activatedLink}
                  >
                    <div className={styles.linkContainer}>
                      <Admin className={styles.navBarIcon} />
                      Admin
                    </div>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to='/profile'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <AccountCircle className={styles.navBarIcon} />
                    Profile
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/upload'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <BackupIcon className={styles.navBarIcon} />
                    Search
                  </div>
                </NavLink>
              </li>
              {previewImageObjects.length > 0 && (
                <li>
                  <NavLink
                    to='/images'
                    className={styles.font}
                    onClick={() => closeNav()}
                    activeClassName={styles.activatedLink}
                  >
                    <div className={styles.linkContainer}>
                      <Image className={styles.navBarIcon} />
                      Search Results
                    </div>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  to='/Orders'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <QueryBuilderIcon className={styles.navBarIcon} />
                    My Orders
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/checkout'
                  className={styles.font}
                  onClick={() => closeNav()}
                  activeClassName={styles.activatedLink}
                >
                  <div className={styles.linkContainer}>
                    <PaymentIcon className={styles.navBarIcon} />
                    Checkout
                  </div>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <a
              className={styles.font}
              onClick={() => {
                closeNav();
                setIsReferModalOpen(true);
              }}
            >
              <People className={styles.navBarIcon} />
              Tell a friend
            </a>
          </li>

          {isAuthenticated && (
            <li>
              <NavLink
                onClick={() => {
                  closeNav();
                  handleLogout();
                }}
                to=''
                className={styles.font}
              >
                <div className={styles.linkContainer}>
                  <ExitToAppIcon className={styles.navBarIcon} />
                  Logout
                </div>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div
        id='empty-container'
        className={styles.emptyContainer}
        onClick={() => closeNav()}
      />
      {isReferModalOpen && (
        <ReferAFriendModal
          closeModal={() => setIsReferModalOpen(false)}
        ></ReferAFriendModal>
      )}
    </div>
  );
};

export default withRouter(NavigationBar);
