import { createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../const/Constants";

const initialState = {
  isSuperUser: false,
  username: "",
  fullname: "",
  email: "",
  schools: [],
  isAuthenticated: false,
  loginSubmitted: false,
  isLoggingIn: false,
  loginErrorMessage: "",
  previousTransactions: [],
  previousTransactionsApiStatus: API_STATUS.IDLE,
  getImagesApiStatus: API_STATUS.IDLE,
};

export const userSlice = createSlice({
  name: "userModel",
  initialState: initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setFullname: (state, action) => {
      state.fullname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setIsSuperUser: (state, action) => {
      if (
        action.payload["cognito:groups"] &&
        action.payload["cognito:groups"].includes("SuperUsers")
      ) {
        state.isSuperUser = true;
      } else {
        state.isSuperUser = false;
      }
    },
    setLoginErrorMessage: (state, action) => {
      state.loginErrorMessage = action.payload;
    },
    setUserAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserSchools: (state, action) => {
      state.schools = action.payload;
    },
    setIsLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload;
    },
    setLoginSubmitted: (state, action) => {
      state.loginSubmitted = action.payload;
    },
    setPreviousTransactionsData: (state, action) => {
      state.previousTransactions = action.payload;
    },

    setPreviousTransactionsDownloadingStatusAtIndex: (state, action) => {
      state.previousTransactions[
        action.payload.transactionIndex
      ].isDownloading = action.payload.isDownloading;
      state.previousTransactions[
        action.payload.transactionIndex
      ].downloadProgress = action.payload.downloadProgress;
      state.previousTransactions[action.payload.transactionIndex].isDownloaded =
        action.payload.isDownloaded;
    },
    setPreviousTransactionsApiStatus: (state, action) => {
      state.previousTransactionsApiStatus = action.payload;
    },
    logout: (state, _) => {
      state = initialState;
    },
  },
});

export const {
  setUsername,
  setEmail,
  setFullname,
  setUserAuthentication,
  setLoginErrorMessage,
  setUserSchools,
  setIsLoggingIn,
  setIsSuperUser,
  setLoginSubmitted,
  setPreviousTransactionsData,
  setPreviousTransactionsApiStatus,
  setPreviousTransactionsDownloadingStatusAtIndex,
  setGetImagesApiStatus,
  logout,
} = userSlice.actions;

export const selectUsername = (state) => state.userModel.username;
export const selectFullname = (state) => state.userModel.fullname;
export const selectEmail = (state) => state.userModel.email;
export const selectUserSchools = (state) => state.userModel.schools;
export const selectIsAuthenticated = (state) => state.userModel.isAuthenticated;
export const selectHasSubmittedLogin = (state) =>
  state.userModel.loginSubmitted;
export const selectIsLoggingIn = (state) => state.userModel.isLoggingIn;
export const selectLoginErrorMessage = (state) =>
  state.userModel.loginErrorMessage;
export const selectPreviousTransactionsData = (state) =>
  state.userModel.previousTransactions;
export const selectPreviousTransactionsApiStatus = (state) =>
  state.userModel.previousTransactionsApiStatus;
export const selectGetImagesApiStatus = (state) =>
  state.userModel.getImagesApiStatus;
export const selectIsSuperUser = (state) => state.userModel.isSuperUser;

export default userSlice.reducer;
