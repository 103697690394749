import Bugsnag from "@bugsnag/js";

export const logBugsnagError = (error) => {
  if (process.env.REACT_APP_IS_DEV === "true") {
    console.log(error);
    return;
  }

  if (error instanceof Error) {
    Bugsnag.notify(error);
    return;
  }

  Bugsnag.notify(new Error(error));
};
