import React, { useState } from "react";
import { API } from "aws-amplify";
import styles from "../Login/Login.module.css";

import Button from "../../components/button/Button";
import InputField from "../../components/inputField/InputField";
import DropdownList from "../../components/dropdownList/DropdownList";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRegistrationSchools,
  selectRegistrationStudentFirstname,
  selectRegistrationStudentSurname,
  selectRegistrationVerifiedStudents,
  setRegistrationSchools,
  setRegistrationStudentFirstname,
  setRegistrationStudentSurnname,
  setRegistrationVerifiedStudents,
} from "../../app/reducers/RegisterSlice";

const VerifyStudent = ({ onCompleteEvent }) => {
  const dispatch = useDispatch();

  const schools = useSelector(selectRegistrationSchools);
  const studentFirstname = useSelector(selectRegistrationStudentFirstname);
  const studentSurname = useSelector(selectRegistrationStudentSurname);

  // TODO remove before going live
  const verifiedStudents = useSelector(selectRegistrationVerifiedStudents);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  let listOfAvailableSchools = [
    "Reddam Atlantic Seaboard",
    "Reddam Constantia",
    "Micklefield",
  ];

  const setSchoolDetails = (name, index) => {
    if (!isVerified) {
      const newSchools = [...schools];
      newSchools[index] = name;
      dispatch(setRegistrationSchools([...newSchools]));
    }
  };

  const removeSchool = (school) => {
    if (!isVerified) {
      const newSchools = [...schools];
      var index = newSchools.indexOf(school);
      newSchools.splice(index, 1);
      dispatch(setRegistrationSchools([...newSchools]));
    }
  };

  const addSchool = () => {
    if (!isVerified && schools.length < 3) {
      const newSchools = [...schools];
      newSchools.push("");
      dispatch(setRegistrationSchools([...newSchools]));
    }
  };

  const validateVerifyStudent = () => {
    return (
      !(studentFirstname && studentSurname && schools[0] !== "") ||
      isVerified ||
      isVerifying
    );
  };

  const verifyStudent = () => {
    dispatch(
      setRegistrationSchools([...schools.filter((school) => school !== "")])
    );
    const schoolsForVerify = schools.filter((school) => school !== "");
    setSubmitted(true);
    setValidationErrorMessage("");
    setIsVerified(false);
    setIsVerifying(true);
    API.post("verifyStudent", "/verifyStudent", {
      body: {
        firstName: studentFirstname,
        surname: studentSurname,
        schools: schoolsForVerify,
      },
    })
      .then((res) => {
        setIsVerifying(false);
        if (res.length === 0) {
          const errMessage = `      
                        Oops.. we have a problem… We couldn't find a matching name in our records, It could be one or more of the following:
                        <ul>
                            <li>That name does not exist in our database of students who attended that school</li>
                            <li>Someone else has already registered to view that student. (<a target="_blank" href="https://forms.gle/8mgdSxVrqPQZvWMT9">click here</a> if you think that may be the case)</li>
                            <li>Or maybe you just spelled the name incorrectly</li>
                        </ul>
                        Give it another go!
                        Hints:
                        <ul>
                            <li>Try using a full name, not a shortened name (EG: Joshua, not Josh.). Or visa versa.</li>
                            <li>If it is a double barreled name try just one or the other of the names (EG: If the name is Charles-Webb, try just Charles or just Webb)</li>
                        </ul>
                    `;

          setValidationErrorMessage(errMessage);
          setIsVerifying(false);
        } else {
          // This checks that all the schools the student chose has corresponding records
          const verifiedStudentSchools = res.map((student) => student.school);
          const unverifiedSchools = [];
          for (let school of schoolsForVerify) {
            if (!verifiedStudentSchools.includes(school)) {
              unverifiedSchools.push(school);
            }
          }

          if (unverifiedSchools.length > 0) {
            const errMessage =
              "We could not find an student for the following school(s): " +
              unverifiedSchools.join(", ");
            setValidationErrorMessage(errMessage);
            setIsVerifying(false);
          } else {
            dispatch(setRegistrationVerifiedStudents(res));
            setIsVerified(true);

            if (process.env.REACT_APP_IS_DEV !== "true") {
              setTimeout(() => {
                onCompleteEvent(2);
              }, 1500);
            }
          }
        }
      })
      .catch((err) => {
        setValidationErrorMessage("An error occurred. Please try again");
        setIsVerifying(false);
        setIsVerified(false);
      });
  };

  const next = () => {
    onCompleteEvent(2);
  };

  const dropdownItems = listOfAvailableSchools.map((schoolName) => {
    return { value: schoolName, label: schoolName };
  });

  return (
    <>
      <div className={styles.headerContainer}>
        <h2 className={styles.headingFont}>Verify Student</h2>
      </div>
      <InputField
        name='studentName'
        type='studentName'
        labelText='Student First Name'
        value={studentFirstname}
        handleChange={(event) =>
          dispatch(setRegistrationStudentFirstname(event.target.value))
        }
        showError={!studentFirstname && submitted}
        errorMessage='Student first name is required'
        disabled={isVerified || isVerifying}
      />
      <InputField
        name='studentSurname'
        type='studentSurname'
        labelText='Student Surname'
        value={studentSurname}
        handleChange={(event) =>
          dispatch(setRegistrationStudentSurnname(event.target.value))
        }
        showError={!studentSurname && submitted}
        errorMessage='Student surname is required'
        disabled={isVerified || isVerifying}
      />

      {schools.map((schoolItem, index) => (
        <div className={index !== 0 ? styles.removableDropdown : ""}>
          <DropdownList
            id='school'
            label='Select a school'
            dropdownOptions={dropdownItems}
            value={schoolItem}
            defaultText='Select a school'
            onSelect={(e) => setSchoolDetails(e.target.value, index)}
            disabled={isVerified || isVerifying}
          />
          {index > 0 && <CancelIcon onClick={() => removeSchool(schoolItem)} />}
        </div>
      ))}

      <div className={styles.addSchoolButton} onClick={() => addSchool()}>
        Add school <AddCircleIcon />
      </div>

      <Button
        onClick={() => verifyStudent()}
        isRedirect={false}
        disabled={validateVerifyStudent()}
        buttonText={
          isVerifying ? (
            "Verifying"
          ) : isVerified ? (
            <CheckCircleOutlineIcon />
          ) : (
            "Verify student"
          )
        }
        isPrimary={true}
      />

      {validationErrorMessage !== "" ? (
        <div
          className={styles.validationErrorMessage}
          dangerouslySetInnerHTML={{ __html: validationErrorMessage }}
        ></div>
      ) : (
        <div className={styles.validationErrorMessageCenter}>
          NOTE: For security reasons, you will be permanently linked to your
          child (if you are the parent) or to yourself (if you are the student).
          This link is not editable after verifying. Also, once you are linked
          to a student nobody else will be able to register by linking to the
          same student. Do not link yourself to a student who is not your child
          or yourself.
        </div>
      )}

      {process.env.REACT_APP_IS_DEV === "true" && (
        <>
          <Button
            onClick={() => next()}
            isRedirect={false}
            buttonText='Next (For dev purposes)'
          ></Button>
          <p>The following text is for dev purposes</p>
          {verifiedStudents.map((student) => {
            return (
              <p>
                {student.fullname} --- {student.school}
              </p>
            );
          })}
        </>
      )}
    </>
  );
};

export default VerifyStudent;
