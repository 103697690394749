import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectImagesInCart,
  selectIsSideCartOpen,
  selectHasOpenedCartBefore,
  toggleSideCart,
  selectPriceOfCart,
  clearCart,
} from "../../app/reducers/CartSlice";
import Close from "@material-ui/icons/Close";
import styles from "./SideBarCart.module.css";
import Button from "../button/Button";
import { SideBarCartImage } from "./SideBarCartImage";

export const SideBarCart = () => {
  const dispatch = useDispatch();
  const isSideCartOpen = useSelector(selectIsSideCartOpen);
  const imagesInCart = useSelector(selectImagesInCart);
  const priceOfCart = useSelector(selectPriceOfCart);
  const hasOpenedCartBefore = useSelector(selectHasOpenedCartBefore);
  const numItemsInCart = imagesInCart.length;

  useEffect(() => {
    toggleCart();
  }, [isSideCartOpen]);

  useEffect(() => {
    if (hasOpenedCartBefore) {
      dispatch(toggleSideCart());
    }
  }, [hasOpenedCartBefore]);

  const images = imagesInCart.map((image) => (
    <SideBarCartImage image={image}> </SideBarCartImage>
  ));

  const toggleCart = () => {
    const menu = document.getElementById("cart-contents");
    const emptyContainer = document.getElementById("empty-cart-container");

    if (isSideCartOpen) {
      menu.style.width = window.innerWidth >= 600 ? "40%" : "60%";
      emptyContainer.setAttribute("class", `${styles.emptyContainerVisible}`);
    } else {
      menu.style.width = "0";
      emptyContainer.setAttribute("class", `${styles.emptyContainer}`);
    }
  };

  const removeAllImagesFromCart = () => {
    dispatch(clearCart());
  };

  return (
    <div className={styles.pageContainer}>
      <div
        id='empty-cart-container'
        className={styles.emptyContainer}
        onClick={() => dispatch(toggleSideCart())}
      />
      <div id='cart-contents' className={styles.sideBarContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>Cart Summary</div>
          {numItemsInCart > 0 && (
            <p className={styles.subHeading}>
              Note: Images that are purchased will not contain a water mark
            </p>
          )}
          <div
            className={styles.closeCart}
            onClick={() => dispatch(toggleSideCart())}
          >
            <Close style={{ fontSize: "35px" }} />
          </div>
          <div className={styles.summaryContainer}>
            <span className={styles.summaryColumn}>
              <span className={styles.totalText}>TOTAL: </span>
              <span className={styles.itemsText}>
                ({numItemsInCart} {numItemsInCart === 1 ? "photo" : "photos"})
              </span>
            </span>
            <span className={styles.summaryColumn}>
              <span className={styles.priceText}>R {priceOfCart}</span>
            </span>
          </div>
          <div className={styles.checkoutButton}>
            <Button
              buttonText='Proceed to checkout'
              isRedirect={true}
              isPrimary={true}
              path='/checkout'
              onClick={() => dispatch(toggleSideCart())}
            />
            <Button
              onClick={() => removeAllImagesFromCart()}
              isPrimary={false}
              isRedirect={false}
              disabled={imagesInCart.length === 0}
              buttonText={"Remove all images in cart"}
            ></Button>
          </div>
        </div>
        <div className={styles.imagesContainer}>{images}</div>
      </div>
    </div>
  );
};

export default SideBarCart;
