import { API } from "aws-amplify";
import React, { useState } from "react";
import styles from "./Admin.module.css";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";
import InputField from "../../components/inputField/InputField";
import { getInviteEmailTemplate } from "../../utils/InviteEmailTemplate";

const SendInviteEmail = ({ goBackToList }) => {
  const username = useSelector(selectUsername);
  const [recipientEmailAddress, setRecipientEmailAddress] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [searchPersonsName, setSearchPersonsName] = useState("");
  const [subject, setSubject] = useState("");
  const [sex, setSex] = useState("");

  const sendEmail = () => {
    const reqObj = {
      username: username,
      recipientEmailAddress: recipientEmailAddress,
      recipientName: recipientName,
      personInSearch: searchPersonsName,
      subject: subject,
      sex: sex.toUpperCase(),
    };

    API.post("sendInvitationEmail", "/sendInvitationEmail", {
      body: reqObj,
    })
      .then((res) => {
        if (res?.error) {
          window.alert(`Error ${res.error}`);
        } else {
          window.alert(`Success`, res);
        }
      })
      .catch((err) => {
        window.alert(`Error: ${err}`);
      });
  };

  const emailAddressErrorMessage = () => {
    if (recipientEmailAddress !== "") {
      const regex = new RegExp(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,10}$/);
      if (!regex.test(recipientEmailAddress)) {
        return "Please use a valid email address";
      }
    }
    return "";
  };

  const previewEmail = () => {
    const preview = getInviteEmailTemplate(
      recipientName,
      searchPersonsName,
      sex
    );
    const newWindow = window.open("Preview", "_blank");
    newWindow.document.write(preview);
  };

  const goBack = () => {
    goBackToList();
  };

  return (
    <div className={styles.AdminContainer}>
      <h2 className={styles.headingContainer}>Send invitation email</h2>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => goBack()}
          isRedirect={false}
          buttonText='Back'
          isPrimary={true}
        />

        <p>USE THIS WITH CAUTION!</p>
        <p>
          AWS will get angry if too many of these emails bounce so make sure the
          email address is correct
        </p>

        <div className={styles.contentContainer}>
          <hr></hr>
          <InputField
            name='subject'
            type='text'
            labelText='Email Subject'
            value={subject}
            handleChange={(event) => setSubject(event.target.value)}
          />

          <InputField
            name='email'
            type='email'
            labelText='Recipient Email'
            value={recipientEmailAddress}
            handleChange={(event) =>
              setRecipientEmailAddress(event.target.value)
            }
            errorMessage={emailAddressErrorMessage()}
            showError={emailAddressErrorMessage().length > 0}
          />
          <InputField
            name='name of recipient'
            type='text'
            labelText='Name of recipient'
            value={recipientName}
            handleChange={(event) => setRecipientName(event.target.value)}
          />
          <InputField
            name='person'
            type='text'
            labelText='Search persons name'
            value={searchPersonsName}
            handleChange={(event) => setSearchPersonsName(event.target.value)}
          />

          <InputField
            name='sex'
            type='text'
            labelText='Male/Female - Type M or F'
            value={sex}
            handleChange={(event) => setSex(event.target.value)}
          />

          <Button
            onClick={() => sendEmail()}
            isRedirect={false}
            buttonText='Send'
            isPrimary={true}
            disabled={recipientEmailAddress === ""}
          />
          <Button
            onClick={() => previewEmail()}
            isRedirect={false}
            buttonText='Preview'
            isPrimary={false}
          />
        </div>
      </div>
    </div>
  );
};
export default SendInviteEmail;
