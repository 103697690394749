import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { removeImageFromCart } from "../../app/reducers/CartSlice";

import { override } from "../../utils/LoaderOverride";
import styles from "./SideBarCart.module.css";

import MoonLoader from "react-spinners/MoonLoader";
import Close from "@material-ui/icons/Close";

export const SideBarCartImage = ({ image }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div key={image.key} className={styles.imageContainer}>
      {isLoading && (
        <div className={styles.sideImageLoader}>
          <MoonLoader
            css={override}
            size={80}
            color={"#484848"}
            loading={isLoading}
          />
        </div>
      )}
      <div style={{ visibility: isLoading ? "hidden" : "" }}>
        <span
          className={styles.removeIcon}
          onClick={() => dispatch(removeImageFromCart(image.key))}
        >
          <Close style={{ fontSize: "35px" }} />
        </span>
        <img src={image.presignedUrl} onLoad={() => setIsLoading(false)} />
      </div>
    </div>
  );
};
