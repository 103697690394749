import React from "react";

import styles from "./AboutUs.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.textContainer}>
        <img
          alt='logo'
          src='/OldSchoolPhotosLogo.png'
          className={styles.logo}
        />
        <div>
          We value your <h3>Privacy</h3>.
        </div>
        <p>
          OldSchoolPhotos.co.za is a website which which stores archived school
          photographs and makes them available to students and parents who have
          left school. We are committed to complying with POPIA regulations, and
          committed to protecting your privacy and confidentiality. The
          photographs are not publicly visible and access to the photographs is
          limited to the students and parents of students who appear in the
          images. We take reasonable steps to ensure that the photographs are
          not viewable by anyone other than the parents or students who appear
          in the photographs. Each user is required to create a personal account
          and password in order to access their photographs. We use face
          recognition software to return matching results when you search for
          your photographs. The information we gather from you and store is:
          Your name, email address and the photographs of you or your child
          which you upload for your search. This information is stored for
          security reasons. We do not store cookies on your devices. The
          information we hold will never be shared with a third party. User
          passwords are encrypted and cannot be viewed or accessed by anyone.
          You can view and edit your personal information by logging into our
          website and clicking on Profile. You can also send us a request to
          edit or remove your information by emailing us -{" "}
          <a href='mailto:info@oldschoolphotos.co.za'>
            info@oldschoolphotos.co.za
          </a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
