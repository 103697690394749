export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "pastschoolphotos",
  },
  rekognition: {
    REGION: "eu-west-2",
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: process.env.REACT_APP_APIGATEWAY_URL,
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: "eu-west-2:717fe4b1-0451-4151-bf80-945c98efabb4",
  },
};
