export const getNamesOfPreviewImages = async (imageList, usersSchools) => {
  const years = [];
  if (!imageList.length) {
    throw new Error("No images were returned.");
  }

  const previewImages = imageList.map((image) => {
    const lowResKey = image.lowResKey;
    const highResKey = lowResKey.replace(/Low_Res/g, "High_Res");
    var imageName = highResKey.substring(14, highResKey.length);
    var schoolName = imageName.split("/")[0];
    imageName = imageName.replaceAll("/", " - ");

    //add incoming years to state for sorting
    const year = highResKey.substring(9, 13);

    return {
      key: highResKey,
      lowerResKey: lowResKey,
      presignedUrl: image.presignedUrl,
      year: year,
      name: imageName,
      school: schoolName,
    };
  });

  previewImages.sort((previewImage1, previewImage2) =>
    previewImage1.key > previewImage2.key ? 1 : -1
  );

  if (previewImages.length) {
    previewImages.forEach((previewImage) => {
      if (!years.includes(previewImage.year)) {
        years.push(previewImage.year);
      }
    });
  }

  return { yearsFromResponse: years, previewImages: previewImages };
};
