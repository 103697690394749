import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";

import styles from "./ImageCard.module.css";
import MoonLoader from "react-spinners/MoonLoader";
import PuffLoader from "react-spinners/PuffLoader";

import AspectRatio from "@material-ui/icons/AspectRatioRounded";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import AddToCart from "@material-ui/icons/AddShoppingCart";
import RemoveFromCart from "@material-ui/icons/RemoveShoppingCart";
import BrokenImage from "@material-ui/icons/BrokenImage";
import Replay from "@material-ui/icons/Replay";
import Done from "@material-ui/icons/Done";

import {
  addImageToCart,
  removeImageFromCart,
} from "../../app/reducers/CartSlice";
import { ImageCardModal } from "./ImageCardModal";
import { logBugsnagError } from "../../utils/Bugsnag";
import { override } from "../../utils/LoaderOverride";

const ImageCard = ({
  imageName,
  imageKey,
  downloadable,
  height,
  showAddToCart,
  presignedUrl,
}) => {
  const dispatch = useDispatch();

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isErrorDownloading, setIsErrorDownloading] = useState(false);
  const [hasBeenDownloaded, setHasBeenDownloaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState("");

  const fileNameWithExtension = imageKey.substring(
    imageKey.lastIndexOf("/") + 1
  );
  const fileName = fileNameWithExtension.split(".")[0];
  const [fileNameToDisplay, setFileNameToDisplay] = useState(fileName);

  useEffect(() => {
    const imageElement = document.getElementById(fileName);
    if (imageElement) {
      const elementWidth = imageElement.offsetWidth;
      const expectedWidthOfFilename = 12 * fileName.length + 10;
      if (expectedWidthOfFilename > elementWidth) {
        const numberOfExcessChars =
          (expectedWidthOfFilename - elementWidth) / 15;
        setFileNameToDisplay(
          fileName.slice(0, fileName.length - numberOfExcessChars + 2) + "..."
        );
      }
    }
  }, [isImageLoading]);

  const isInCart = useSelector(
    (state) =>
      state.cartModel.imagesInCart.filter((item) => item.key === imageKey)
        .length > 0
  );

  const downloadImage = () => {
    setIsDownloading(true);
    setIsErrorDownloading(false);
    setHasBeenDownloaded(false);
    API.post("getImageObject", "/getImageObject", {
      body: { key: imageName },
    })
      .then(async (keyImagePair) => {
        const res = await fetch(keyImagePair.presignedUrl);
        const blob = await res.blob();
        const file = URL.createObjectURL(blob);

        const element = document.createElement("a");
        element.href = file;
        element.download = fileNameWithExtension;

        element.click();
        setIsDownloading(false);
        setHasBeenDownloaded(true);
      })
      .catch((err) => {
        logBugsnagError(err);
        setIsDownloading(false);
        setIsErrorDownloading(false);
      });
  };

  const handleAddToCartClick = () => {
    dispatch(addImageToCart({ key: imageKey, presignedUrl: presignedUrl }));
  };

  const handleRemoveFromCartClick = () => {
    dispatch(removeImageFromCart(imageKey));
  };

  const enlargeImage = () => {
    setIsModalOpen(true);
  };

  const imageStyling = { height: `${height}px` };

  const getIcon = () => {
    if (downloadable && isDownloading) {
      return (
        <div className={styles.iconWrapper}>
          <PuffLoader
            css={override}
            size={25}
            color={"#000"}
            loading={isDownloading}
          />
        </div>
      );
    }

    if (downloadable && isErrorDownloading) {
      return (
        <div className={styles.iconWrapper}>
          <Replay onClick={() => downloadImage()} />
        </div>
      );
    }

    if (downloadable && hasBeenDownloaded) {
      return (
        <div className={styles.iconWrapper}>
          <Done />
        </div>
      );
    }

    if (downloadable) {
      return (
        <div className={styles.iconWrapper}>
          {" "}
          <DownloadIcon onClick={() => downloadImage()} />
        </div>
      );
    }

    if (showAddToCart && !isInCart) {
      return (
        <div
          className={styles.cartButtonWrapper}
          onClick={() => handleAddToCartClick()}
        >
          Add to cart <AddToCart />
        </div>
      );
    }
    if (showAddToCart && isInCart) {
      return (
        <div
          className={styles.cartButtonWrapper}
          onClick={() => handleRemoveFromCartClick()}
        >
          Remove <RemoveFromCart />
        </div>
      );
    }
  };

  if (isError) {
    return (
      <div className={styles.errorContainer} style={imageStyling}>
        <BrokenImage />
        <p>Could not load image</p>
      </div>
    );
  }

  const srcText = presignedUrl;
  return (
    <>
      <div
        className={styles.errorContainer}
        style={{
          ...imageStyling,
          display: isImageLoading ? "" : "none",
        }}
      >
        <MoonLoader
          css={override}
          size={80}
          color={"#484848"}
          loading={isImageLoading}
        />
      </div>
      <div
        className={styles.cardWrapper}
        style={{ visibility: isImageLoading ? "hidden" : "" }}
      >
        <div className={styles.cardContainer}>
          <div>
            <div className={styles.fileName}>{fileNameToDisplay}</div>
            <div className={styles.imageCard}>
              <img
                src={srcText}
                key={fileName}
                className={styles.image}
                loading='lazy'
                style={isImageLoading ? { height: "0" } : {}}
                onLoad={() => setIsImageLoading(false)}
                onError={() => setIsError(true)}
              ></img>
            </div>
          </div>
          <div className={styles.keyContainer}>
            {getIcon()}
            <div className={styles.iconWrapper} onClick={() => enlargeImage()}>
              <AspectRatio />
            </div>
          </div>
        </div>
        {!isDownloading && isErrorDownloading && (
          <div className={styles.downloadError}>
            An error occurred while downloading. Please try again
          </div>
        )}

        {isModalOpen && (
          <ImageCardModal
            imgSrc={srcText}
            closeModal={() => setIsModalOpen(false)}
          ></ImageCardModal>
        )}
      </div>
    </>
  );
};

export default ImageCard;
