import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";

import "./Uploader.css";

import {
  selectUploadFileName,
  setAvoidSearchLimit,
  setShouldSearchAgain,
  setUpload,
  setUploadFileName,
} from "../../app/reducers/UploaderSlice";

import styles from "./Uploader.module.css";

import Check from "@material-ui/icons/Check";
import PuffLoader from "react-spinners/PuffLoader";

import ImageUploader from "react-images-upload";
import Button from "../../components/button/Button";

import { useHistory } from "react-router";
import { selectUsername } from "../../app/reducers/UserSlice";
import { override } from "../../utils/LoaderOverride";

const Uploader = ({ goBack }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const username = useSelector(selectUsername);
  const fileName = useSelector(selectUploadFileName);

  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [hasConfirmedPopi, setHasConfirmedPopi] = useState(false);

  const [numberOfSearches, setNumberOfSearches] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(setAvoidSearchLimit(false));
    dispatch(setUploadFileName(""));
    getNumberOfSearches();
  }, []);

  const getNumberOfSearches = () => {
    setIsLoading(true);
    API.post("getNumberOfSearches", "/getNumberOfSearches", {
      body: { username: username },
    })
      .then((res) => {
        setNumberOfSearches(res.numberOfSearches);
        setIsLoading(false);
      })
      .catch(() => {
        setNumberOfSearches(3);
        setIsLoading(false);
      });
  };

  const onDrop = (picture) => {
    setHasConfirmed(false);
    let reader = new FileReader();
    try {
      reader.readAsDataURL(picture[0]);
      const blobPromise = new Promise(function (resolve, reject) {
        reader.onload = function (e) {
          resolve(e.target.result.substring(e.target.result.indexOf(",") + 1));
        };
      });
      dispatch(setUploadFileName(picture[0].name));
      blobPromise.then((img) => {
        dispatch(setUpload(img));
      });
    } catch (error) {
      dispatch(setUploadFileName(""));
    }
  };

  const onGoClick = () => {
    dispatch(setShouldSearchAgain(true));
    history.push("/images");
  };

  return (
    <div
      className={`${styles.uploaderWrapper} ${
        fileName === "" && styles.fullHeight
      }`}
    >
      <div className={styles.heading}>
        {fileName === "" ? (
          <div>
            <h2>Upload a new photo</h2>
            {!isLoading && numberOfSearches !== 0 && (
              <>
                <h6 className={styles.topMargin}>
                  The photo must be of you or your child.
                  <br></br>
                  <br></br>
                  Your search image will be recorded permanently below.
                  <br></br>
                  Make sure your image contains a single face, is easily
                  identifiable and is not blurry.
                </h6>
                {numberOfSearches !== undefined && (
                  <div>You have {numberOfSearches} searches left</div>
                )}
              </>
            )}

            {numberOfSearches === 0 && (
              <div className={styles.noSearchesLeft}>
                <h6>You have 0 searches left</h6>
                <h6>
                  Contact us or search for an image that you have searched
                  previously
                </h6>
                <div className={styles.topMargin}>
                  <a
                    className={styles.contactUsButton}
                    href='mailto:info@oldschoolphotos.co.za?subject=Image upload limit reached'
                  >
                    <Button
                      isRedirect={false}
                      buttonText='Contact Us'
                      disabled={false}
                      isPrimary={true}
                    />
                  </a>
                  {goBack && (
                    <div className={styles.contactUsButton}>
                      <Button
                        isRedirect={false}
                        buttonText='Back'
                        disabled={false}
                        onClick={() => goBack()}
                        isPrimary={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <h2>Use this image to search?</h2>
            <h6 className={styles.topMargin}>
              You are only allowed to perform a total of 3 searches
            </h6>
          </div>
        )}
      </div>

      <div
        className={`${styles.uploaderContainer} ${
          fileName !== ""
            ? styles.uploaderContainerUploaded
            : styles.uploaderContainerNotUploaded
        }`}
      >
        {isLoading ? (
          <div className={styles.topMargin}>
            <PuffLoader
              css={override}
              size={100}
              color={"#484848"}
              radius={"0.5em"}
              loading={true}
            />
          </div>
        ) : (
          numberOfSearches !== 0 && (
            <>
              <ImageUploader
                withIcon={true}
                buttonText='Upload'
                onChange={(img) => onDrop(img)}
                imgExtension={[".jpg", ".jpeg", ".png"]}
                maxFileSize={5242880}
                withPreview={true}
                withLabel={true}
                singleImage={true}
                fileContainerStyle={{
                  border: "none",
                  boxShadow: "none",
                  width: "100%",
                  paddingBottom: 0,
                }}
                label={"File limit: 5mb,  File types: .jpg .jpeg .png"}
              />

              {fileName === "" && goBack && (
                <div className={styles.goBackButtonContainer}>
                  <Button
                    isRedirect={false}
                    buttonText='Back'
                    disabled={false}
                    onClick={() => goBack()}
                    isPrimary={false}
                  />
                </div>
              )}

              {fileName !== "" && (
                <>
                  <div className={styles.imageCaption}>
                    <div
                      className={styles.checkbox}
                      data-checked={hasConfirmed ? "checked" : "unchecked"}
                      onClick={() => setHasConfirmed(!hasConfirmed)}
                    >
                      {hasConfirmed && <Check />}
                    </div>
                    <label className={styles.checkboxText}>
                      I confirm that this is a photo of me or my child and that
                      it will be used to search for photos in our image library.
                    </label>
                  </div>
                  <div className={styles.imageCaption}>
                    <div
                      className={styles.checkbox}
                      data-checked={hasConfirmedPopi ? "checked" : "unchecked"}
                      onClick={() => setHasConfirmedPopi(!hasConfirmedPopi)}
                    >
                      {hasConfirmedPopi && <Check />}
                    </div>
                    <label className={styles.checkboxText}>
                      I am aware that in terms of the POPI act I am not allowed
                      to share photos of other people on social media without
                      their permission.
                    </label>
                  </div>
                  <div className={styles.goButtonContainer}>
                    <Button
                      onClick={() => onGoClick()}
                      isPrimary={true}
                      isRedirect={false}
                      buttonText='Go'
                      disabled={!hasConfirmed || !hasConfirmedPopi}
                    ></Button>
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};

export default Uploader;
