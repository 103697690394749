import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import styles from "./Admin.module.css";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";
import InputField from "../../components/inputField/InputField";
import DropdownList from "../../components/dropdownList/DropdownList";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Check from "@material-ui/icons/Check";

const CreateUser = ({ user, goBackToList }) => {
  const username = useSelector(selectUsername);
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [schools, setSchools] = useState([""]);
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {
    if (user) {
      setFullname(user.fullname);
      setEmail(user.email);
      setUserExists(true);
    }
  }, []);

  const createUser = () => {
    const schoolsForVerify = schools.filter((school) => school !== "");
    const reqObj = {
      username: username,
      fullname: fullname,
      emailAddress: email,
      schools: schoolsForVerify,
      userExists: userExists,
    };

    API.post("createUser", "/createUser", {
      body: reqObj,
    })
      .then((res) => {
        window.alert(`Success`);
      })
      .catch((err) => {
        window.alert(`Error: ${err}`);
      });
  };

  const goBack = () => {
    goBackToList();
  };

  let listOfAvailableSchools = [
    "Reddam Atlantic Seaboard",
    "Reddam Constantia",
    "Micklefield",
  ];

  const dropdownItems = listOfAvailableSchools.map((schoolName) => {
    return { value: schoolName, label: schoolName };
  });

  const setSchoolDetails = (name, index) => {
    const newSchools = [...schools];
    newSchools[index] = name;
    setSchools([...newSchools]);
  };

  const removeSchool = (school) => {
    const newSchools = [...schools];
    var index = newSchools.indexOf(school);
    newSchools.splice(index, 1);
    setSchools([...newSchools]);
  };

  const addSchool = () => {
    if (schools.length < 3) {
      const newSchools = [...schools];
      newSchools.push("");
      setSchools([...newSchools]);
    }
  };

  return (
    <div className={styles.AdminContainer}>
      <h2 className={styles.headingContainer}>Create User</h2>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => goBack()}
          isRedirect={false}
          buttonText='Back'
          isPrimary={true}
        />

        <p>
          If the user does not exist, enter their details, select a school and
          leave the checkbox unchecked.
        </p>
        <p>
          If the user does exist and their status is "FORCE_CHANGE_PASSWORD",
          enter their details, don't select schools and check the checkbox.
        </p>
        <p>
          If the user does exist and their status is "CONFIRMED" but they want
          to change their password, the user must use the "forgot password"
          feature.
        </p>
        <div className={styles.contentContainer}>
          <hr></hr>
          <InputField
            name='fullname'
            type='text'
            labelText='Full name'
            value={fullname}
            handleChange={(event) => setFullname(event.target.value)}
          />
          <InputField
            name='email'
            type='email'
            labelText='Email Address'
            value={email}
            handleChange={(event) => setEmail(event.target.value)}
          />

          {schools.map((schoolItem, index) => (
            <div className={index !== 0 ? styles.removableDropdown : ""}>
              <DropdownList
                id='school'
                label='Select a school'
                dropdownOptions={dropdownItems}
                value={schoolItem}
                defaultText='Select a school'
                onSelect={(e) => setSchoolDetails(e.target.value, index)}
              />
              {index > 0 && (
                <CancelIcon onClick={() => removeSchool(schoolItem)} />
              )}
            </div>
          ))}

          <div className={styles.addSchoolButton} onClick={() => addSchool()}>
            Add school <AddCircleIcon />
          </div>

          <div className={styles.userExistsContainer}>
            <div
              className={styles.checkbox}
              data-checked={userExists ? "checked" : "unchecked"}
              onClick={() => setUserExists(!userExists)}
            >
              {userExists && <Check />}
            </div>
            <label className={styles.checkboxText}>
              Does this user exists?
            </label>
          </div>

          <Button
            onClick={() => createUser()}
            isRedirect={false}
            buttonText='Create user'
            isPrimary={true}
          />
        </div>
      </div>
    </div>
  );
};
export default CreateUser;
