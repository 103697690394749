import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import RemoveFromCart from "@material-ui/icons/RemoveShoppingCart";

import {
  selectImagesInCart,
  toggleSideCart,
  selectPriceOfCart,
  selectCurrentImagePrice,
  clearCart,
} from "../../app/reducers/CartSlice";
import styles from "./BottomCartDetails.module.css";

export const BottomCartDetails = () => {
  const dispatch = useDispatch();
  const imagesInCart = useSelector(selectImagesInCart);
  const priceOfCart = useSelector(selectPriceOfCart);
  const pricePerPicture = useSelector(selectCurrentImagePrice);
  const numItemsInCart = imagesInCart.length;

  useEffect(() => {
    const rootContainer = document.getElementById("rootContainer");
    if (rootContainer && numItemsInCart > 0) {
      rootContainer.style.paddingBottom = "80px";
    }
    if (rootContainer && numItemsInCart === 0) {
      rootContainer.style.paddingBottom = "0";
    }
  }, [numItemsInCart]);

  if (numItemsInCart > 0) {
    return (
      <div className={styles.bottomDetailsContainer}>
        <div className={styles.detailsContainer}>
          <div className={styles.row}>
            <p>Images in cart:</p>
            <p>{numItemsInCart}</p>
          </div>
          <div className={styles.row}>
            <p>Price:</p>
            <p>R{priceOfCart}</p>
          </div>
          <div className={styles.row}>
            <p>Price per picture:</p>
            <p>R{pricePerPicture}</p>
          </div>
        </div>
        <div
          className={styles.clearCartContainer}
          onClick={() => dispatch(clearCart())}
        >
          <div className={styles.cartIcon}>
            <RemoveFromCart style={{ fill: "black", fontSize: "32px" }} />
          </div>
          <span>Clear Cart</span>
        </div>
        <div
          className={styles.openCartContainer}
          onClick={() => dispatch(toggleSideCart())}
        >
          <div className={styles.cartIcon}>
            <ShoppingCartIcon style={{ fill: "black", fontSize: "32px" }} />
          </div>
          <span>Open cart</span>
        </div>
      </div>
    );
  } else {
    return "";
  }
};

export default BottomCartDetails;
