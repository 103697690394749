import React from "react";
import styles from "./Login.module.css";
import {
  setUserAuthentication,
  setEmail,
  setUserSchools,
  setUsername,
} from "../../app/reducers/UserSlice";
import { connect } from "react-redux";

import { Auth } from "aws-amplify";
import InputField from "../../components/inputField/InputField";
import Button from "../../components/button/Button";

class ConfirmRegistrationCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmingOrSending: true,
      errorMessage: "",
      isLoading: false,

      email: "",
      password: "",
      code: "",
    };

    this.handleConfirmCodeSubmit = this.handleConfirmCodeSubmit.bind(this);
    this.handleSendCodeSubmit = this.handleSendCodeSubmit.bind(this);
    this.validateConfirmCodeForm = this.validateConfirmCodeForm.bind(this);
    this.validateSendCodeForm = this.validateSendCodeForm.bind(this);
    this.handleSendCodeClick = this.handleSendCodeClick.bind(this);
  }

  handleSendCodeClick() {
    this.setState({ errorMessage: "", confirmingOrSending: false });
  }

  validateSendCodeForm() {
    return this.state.email.length > 0;
  }

  validateConfirmCodeForm() {
    return (
      this.state.code.length > 0 &&
      this.state.password.length > 6 &&
      this.state.email.length > 0
    );
  }

  async handleSendCodeSubmit() {
    const { email } = this.state;
    this.setState({ isLoading: true, errorMessage: "" });
    try {
      await Auth.resendSignUp(email);
      this.setState({ confirmingOrSending: true, isLoading: false });
    } catch (e) {
      this.setState({ errorMessage: e.message, isLoading: false });
    }
  }

  async handleConfirmCodeSubmit() {
    const { email, code, password } = this.state;
    this.setState({ isLoading: true, errorMessage: "" });

    try {
      await Auth.confirmSignUp(email, code);
      const userObj = await Auth.signIn(email, password);
      this.setState({ isLoading: false });

      this.props.setUserAuthentication(true);
      this.props.setEmail(email);
      this.props.setUsername(userObj.username);
      this.props.setUserSchools(
        userObj.attributes["custom:School1"].split(",")
      );

      setTimeout(() => {
        this.props.history.push("/");
      }, 1000);
    } catch (e) {
      this.setState({ errorMessage: e.message, isLoading: false });
    }
  }

  renderSendCodeForm() {
    const { email, errorMessage, isLoading } = this.state;
    return (
      <div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Resend Code</h2>
        </div>
        <InputField
          name='email'
          type='email'
          labelText='Email Address'
          value={email}
          handleChange={(event) =>
            this.setState({ ...this.state, email: event.target.value })
          }
        />

        <div class={styles.buttonContainer}>
          <Button
            onClick={() => this.handleSendCodeSubmit()}
            isRedirect={false}
            disabled={!this.validateSendCodeForm()}
            buttonText='Send'
            isPrimary={true}
          />

          {isLoading && (
            <div className={styles.loaderContainer}>
              <img
                alt='loading'
                class={styles.loader}
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            </div>
          )}
          <div>{errorMessage}</div>
        </div>
      </div>
    );
  }

  renderConfirmCodeForm() {
    const { email, password, code, errorMessage, isLoading } = this.state;
    return (
      <div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Confirm Code</h2>
        </div>
        <InputField
          name='email'
          type='email'
          labelText='Email Address'
          value={email}
          handleChange={(event) =>
            this.setState({ ...this.state, email: event.target.value })
          }
        />
        <InputField
          name='password'
          type='password'
          labelText='Password'
          value={password}
          handleChange={(event) =>
            this.setState({ ...this.state, password: event.target.value })
          }
        />
        <InputField
          name='confirmation code'
          type='text'
          labelText='Confirmation code'
          value={code}
          handleChange={(event) =>
            this.setState({ ...this.state, code: event.target.value })
          }
        />
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => this.handleConfirmCodeSubmit()}
            isRedirect={false}
            disabled={!this.validateConfirmCodeForm()}
            buttonText='Confirm'
            isPrimary={true}
          />
          <Button
            onClick={() => this.handleSendCodeClick()}
            isRedirect={false}
            buttonText='Resend Code'
            isPrimary={false}
          />
          <div className={styles.loginErrorMessage}>
            If you did not receive your code, please email us here:{" "}
            <a href='mailto:info@oldschoolphotos.co.za?subject=Missing Verification Code'>
              info@oldschoolphotos.co.za
            </a>
          </div>
          {isLoading && (
            <div className={styles.loaderContainer}>
              <img
                alt='loading'
                class={styles.loader}
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            </div>
          )}
          <div>{errorMessage}</div>
        </div>
      </div>
    );
  }

  render() {
    const { confirmingOrSending } = this.state;
    return (
      <div className={styles.loginContainer}>
        {confirmingOrSending
          ? this.renderConfirmCodeForm()
          : this.renderSendCodeForm()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUsername: (username) => dispatch(setUsername(username)),
    setUserAuthentication: (par) => dispatch(setUserAuthentication(par)),
    setEmail: (email) => dispatch(setEmail(email)),
    setUserSchools: (schools) => dispatch(setUserSchools(schools)),
  };
};

export default connect(null, mapDispatchToProps)(ConfirmRegistrationCode);
