import React from "react";
import "./NotFound.css";
import ErrorIcon from '@material-ui/icons/ErrorOutline';


export default function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found-icon"><ErrorIcon></ErrorIcon></div>
      <h3>Sorry, page not found!</h3>
    </div>
  );
}