import React, { useState } from "react";
import { Auth } from "aws-amplify";

import styles from "../Login/Login.module.css";
import Button from "../../components/button/Button";
import InputField from "../../components/inputField/InputField";
import { useDispatch, useSelector } from "react-redux";

import {
  setUserAuthentication,
  setEmail,
  setUserSchools,
  selectIsAuthenticated,
  setUsername,
  setIsSuperUser,
} from "../../app/reducers/UserSlice";
import {
  selectRegistrationEmail,
  selectRegistrationPassword,
} from "../../app/reducers/RegisterSlice";

const ConfirmationCode = ({ onCompleteEvent }) => {
  const dispatch = useDispatch();

  const email = useSelector(selectRegistrationEmail);
  const password = useSelector(selectRegistrationPassword);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [submittedConfirmationCode, setSubmittedConfirmationCode] =
    useState(false);

  const handleConfirmationFormSubmit = async (event) => {
    setIsConfirming(true);
    setSubmittedConfirmationCode(true);

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      const userObj = await Auth.signIn(email, password);
      const userSession = await Auth.currentSession();
      dispatch(setIsSuperUser(userSession.getIdToken().payload));

      dispatch(setUserAuthentication(true));
      setIsConfirming(false);
      dispatch(setEmail(email));
      dispatch(setUsername(userObj.username));
      dispatch(setUserSchools(userObj.attributes["custom:School1"].split(",")));

      onCompleteEvent();
    } catch (e) {
      dispatch(setUserAuthentication(false));
      setIsConfirming(false);
    }
  };

  const validateConfirmationForm = () => {
    return confirmationCode.length > 0;
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <h2 className={styles.headingFont}>Confirmation Code</h2>
      </div>
      <div>
        <InputField
          name='confirmationcode'
          type='confirmationcode'
          labelText='Confirmation Code'
          value={confirmationCode}
          handleChange={(event) => setConfirmationCode(event.target.value)}
          showError={submittedConfirmationCode && !confirmationCode}
          errorMessage='Confirmation code is required'
        />
      </div>
      <div className='form-group'>
        <Button
          onClick={() => handleConfirmationFormSubmit()}
          isRedirect={false}
          disabled={!validateConfirmationForm()}
          buttonText='Verify'
          isPrimary={true}
        />
        <div className={styles.spamFolderMessage}>
          Please check your email (and spam folder) for a verification code.
        </div>

        <div className={styles.spamFolderMessage}>
          If you did not receive your code, please email us here:{" "}
          <a href='mailto:info@oldschoolphotos.co.za?subject=Missing Verification Code'>
            info@oldschoolphotos.co.za
          </a>
        </div>

        {isConfirming && (
          <div className={styles.loaderContainer}>
            <img
              alt='loading'
              class={styles.loader}
              src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
            />
          </div>
        )}
        {!isAuthenticated && submittedConfirmationCode && !isConfirming && (
          <div className={styles.loaderContainer}>
            <img alt='cross' src='x-sym.jpg' class={styles.ximg} />
          </div>
        )}
        {isAuthenticated && submittedConfirmationCode && !isConfirming && (
          <div className={styles.loaderContainer}>
            <img alt='tick' src='tick.png' class={styles.ximg} />
          </div>
        )}
      </div>
    </>
  );
};
export default ConfirmationCode;
