import React, { useState } from "react";
import styles from "./InputField.module.css";
import Hide from "@material-ui/icons/Visibility";
import Show from "@material-ui/icons/VisibilityOff";

const InputField = ({
  name,
  type,
  labelText,
  value,
  handleChange,
  errorMessage,
  showError,
  disabled,
  isPasswordField,
}) => {
  let placeholder;
  if (!value) {
    placeholder = labelText;
  }

  const [isVisible, setIsVisible] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [isDirty, setIsDirty] = useState(false);

  const toggleShowHide = () => {
    if (inputType === "password") {
      setInputType(null);
    } else {
      setInputType("password");
    }
    setIsVisible(!isVisible);
  };

  return (
    <div
      className={styles.inputFieldContainer + (showError ? " has-error" : "")}
    >
      <div className={styles.labelContainer}>
        <label className={styles.labelFont} htmlFor={name}>
          {value && labelText}
        </label>
      </div>
      <input
        type={inputType}
        className='form-control rounded-0 lg '
        placeholder={placeholder}
        name={name}
        value={value === null ? "" : value}
        onChange={handleChange}
        disabled={disabled}
        onBlur={() => setIsDirty(true)}
      />

      {isPasswordField && (
        <div className={styles.showHideButton} onClick={() => toggleShowHide()}>
          {isVisible ? <Hide /> : <Show />}
        </div>
      )}

      {showError && isDirty && (
        <div className={styles.helpBlock + " help-block"}>{errorMessage}</div>
      )}
    </div>
  );
};

export default InputField;
