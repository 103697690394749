import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../../app/reducers/UserSlice";
import InputField from "../../components/inputField/InputField";
import { getPrice, getPricePerImage } from "../../utils/price";

import styles from "./AboutUs.module.css";

const AboutUs = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [numImages, setNumImages] = useState(1);

  return (
    <div className={styles.aboutUsContainer}>
      <div className={styles.textContainer}>
        <img
          alt='logo'
          src='/OldSchoolPhotosLogo.png'
          className={styles.logo}
        />
        <div>
          <h3>Welcome</h3> to your school memory bank
        </div>
        {!isAuthenticated && (
          <div>
            Unlock your school memories by <a href='/register'>registering</a>{" "}
            and <a href='/login'>logging in</a>.
          </div>
        )}
        <p>
          If you were at Reddam Constantia or Reddam Atlantic Seaboard between
          2002 and 2013 or Micklefield between 2010 and 2013, you will find all
          the photos of you while you were at school. That is all your class
          photos, sports teams, cultural groups, individual portraits and any
          event you took part in, for example, your matric dance, fashion show
          or any school productions. So grab a coffee, login and get ready for a
          trip down memory lane!
        </p>
        <br></br>
        <hr></hr>
        <br></br>
        <div>
          We take <h3>Security</h3> seriously…
        </div>
        <p>
          This is a closed site, limited to only students and parents who were
          part of the Reddam and Micklefield Family. So bear with us, when you
          register you will have to jump through a few security hoops so we can
          be sure you are you. You will not be able to register otherwise.
        </p>

        <br></br>
        <hr></hr>
        <br></br>

        <div>
          Understand our <h3>Pricing</h3>
        </div>
        <p>
          The more images you purchase the cheaper they are, it's a sliding
          scale.
        </p>
        <p>
          Only high res digital downloads are available on this site. The prices
          work on a sliding scale, the more you buy the less they cost, however,
          we do cap the cost at R1500. So If you buy 75 or more the cost will be
          R1500.
        </p>
        <br></br>
        <div className={styles.pricingDetails}>
          <div className={styles.inputFieldWrapper}>
            <div className={styles.inputFieldContainer}>
              <h5>Try it out</h5>
              <InputField
                name='Number of images'
                type='number'
                labelText='Number of images'
                value={numImages}
                handleChange={(event) => setNumImages(event.target.value)}
              />
            </div>
            <br></br>
            <p>Price: R {getPrice(numImages)}</p>
            <p>Price per image: R {getPricePerImage(numImages)}</p>
          </div>
          <ul>
            <li>1 to 10 Images are R 40 per image</li>
            <li>11 to 20 images are R 30 per image</li>
            <li>21 to 50 images are R 25 per image</li>
            <li>51 to 75 images are R 20 per image</li>
            <li>75 or more are R1500 total</li>
          </ul>
        </div>

        <br></br>
        <hr></hr>
        <br></br>

        <div>
          We also take your <h3>Privacy</h3> seriously...
        </div>
        <p>
          We will never share any information you give us with anyone. Ever.{" "}
          <a href='/privacy-policy'>(See our privacy statement here POPIA)</a>
        </p>
        <hr></hr>
        <p>
          Contact us -{" "}
          <a href='mailto:info@oldschoolphotos.co.za'>
            info@oldschoolphotos.co.za
          </a>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
