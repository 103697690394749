import React from "react";
import styles from "./DownloadModal.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Close from "@material-ui/icons/Close";

const DownloadModal = ({
  downloadProgress,
  closeModal,
  totalNumberToDownload,
  numberOfImagesDownloaded,
}) => {
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modal}>
        <div className={styles.closeModal} onClick={() => closeModal()}>
          <Close style={{ fontSize: "35px" }} />
        </div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Your download has started</h2>
        </div>

        <h5> Please do not close the browser or tab.</h5>

        <div className={styles.progressCircle}>
          <CircularProgressbar
            value={downloadProgress}
            styles={buildStyles({
              pathColor: "#484848",
            })}
          />
        </div>
        <p>
          Downloaded {numberOfImagesDownloaded} out of {totalNumberToDownload}
        </p>
        <p>
          These are high-quality images which means the download will take quite
          a while.
        </p>
        <p>
          Sit back, grab a cup of coffee and get ready to see your school
          memories.
        </p>
      </div>
    </div>
  );
};

export default DownloadModal;
