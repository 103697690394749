export const getPrice = (numItems) => {
  if (numItems >= 75) return 1500;
  if (numItems > 50) return numItems * 20;
  if (numItems > 20) return numItems * 25;
  if (numItems > 10) return numItems * 30;

  return numItems * 40;
};

export const getPricePerImage = (numItems) => {
  if (numItems >= 75) {
    return (1500 / numItems).toFixed(2);
  }

  if (numItems > 50) return 20;
  if (numItems > 20) return 25;
  if (numItems > 10) return 30;

  return 40;
};
