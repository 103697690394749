import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import PastSearchImageCard from "../../components/pastSearchImageCard/PastSearchImageCard";
import styles from "./Admin.module.css";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { selectUsername } from "../../app/reducers/UserSlice";
import { override } from "../../utils/LoaderOverride";
import { formatVerifiedStudents } from "./utils";

const UserProfile = ({ user, goBackToList }) => {
  const username = useSelector(selectUsername);

  const [searchesError, setSearchesError] = useState("");
  const [isLoadingSearches, setIsLoadingSearches] = useState(true);
  const [transactionsError, setTransactionsError] = useState("");
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [pastSearchImageKeysAndUrls, setPastSearchImageKeysAndUrls] = useState(
    []
  );

  const [previousTransaction, setPreviousTransactions] = useState([]);

  useEffect(() => {
    getPastSearches();
    getUserTransactions();
  }, []);

  const getPastSearches = () => {
    setSearchesError("");
    setIsLoadingSearches(true);
    API.post("getPastSearches", "/getPastSearches", {
      body: { username: user.username, email: user.email },
    })
      .then((res) => {
        setPastSearchImageKeysAndUrls(res);
        setIsLoadingSearches(false);
      })
      .catch((err) => {
        window.alert(`Error getting past searches: ${err}`);
        setIsLoadingSearches(false);
        setSearchesError("Oops, something went wrong. Please try again.");
      });
  };

  const getUserTransactions = () => {
    setIsLoadingTransactions(true);
    const reqObj = { username: user.username, excludeImages: true };
    API.post("getPurchasedImages", "/getPurchasedImages", {
      body: reqObj,
    })
      .then((res) => {
        setIsLoadingTransactions(false);
        if (res.transactions.Count > 0) {
          setPreviousTransactions(res.transactions.Items);
        } else {
          setTransactionsError("No transactions");
        }
      })
      .catch((err) => {
        window.alert(`Error getting purchases images: ${err}`);
        setIsLoadingTransactions(false);
        setTransactionsError(err);
      });
  };

  const deleteUser = () => {
    // TODO Fix lambda
    // const reqObj = {
    //   username: username,
    //   usernameToDelete: user.username,
    //   emailToDelete: user.email,
    // };
    // API.post("deleteUser", "/deleteUser", {
    //   body: reqObj,
    // })
    //   .then(() => {
    //     window.alert(`Success`);
    //   })
    //   .catch((err) => {
    //     window.alert(`Error deleting user: ${err}`);
    //   });
  };

  const resetSearches = () => {
    const reqObj = { username: user.username };
    API.post("resetPastSearchCount", "/resetPastSearchCount", {
      body: reqObj,
    })
      .then(() => {
        window.alert(`Success`);
      })
      .catch((err) => {
        window.alert(`Error resetting search count: ${err}`);
      });
  };

  const dateToString = (date) => {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      date.getFullYear(),
    ].join("/");
  };

  const formatTransactions = () => {
    if (!previousTransaction.length) return;
    return (
      <>
        <h3>User transactions</h3>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Order Number</th>
              <th>Amount Paid</th>
              <th>Payment method</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {previousTransaction.map((transaction) => {
              const date =
                typeof transaction.dateOfOrder == "string" &&
                new Date(parseInt(transaction.dateOfOrder));

              return (
                <tr>
                  <td>{date && dateToString(date)}</td>
                  <td>{transaction.orderNumber}</td>
                  <td>{transaction.amountPaid}</td>
                  <td>{transaction.paymentMethod}</td>
                  <td>{transaction.paymentStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  const confirmUser = () => {
    const reqObj = {
      adminUsername: username,
      username: user.username,
    };
    API.post("confirmUser", "/confirmUser", {
      body: reqObj,
    })
      .then(() => {
        window.alert(`Success`);
      })
      .catch((err) => {
        window.alert(`Error confirming user: ${err}`);
      });
  };

  const goBack = () => {
    goBackToList();
  };

  return (
    <div className={styles.AdminContainer}>
      <h2 className={styles.headingContainer}>User</h2>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => goBack()}
          isRedirect={false}
          buttonText='Back'
          isPrimary={true}
        />
        {user.userStatus === "UNCONFIRMED" && (
          <>
            <Button
              onClick={() => confirmUser()}
              isRedirect={false}
              buttonText='Confirm User'
              isPrimary={true}
            />
            <p>Before confirming, make sure they have a school</p>
          </>
        )}

        {/* <Button
          onClick={() => deleteUser()}
          isRedirect={false}
          buttonText='Delete user'
          isPrimary={true}
        /> */}
      </div>
      <div className={styles.contentContainer}>
        <div>
          <p>
            <b>Username:</b> {user.username}
          </p>
        </div>
        <div>
          <b>Email:</b> <p>{user.email}</p>
        </div>
        <div>
          <b>Fullname:</b> <p>{user.fullname}</p>
        </div>
        <div>
          <b>Status:</b> <p>{user.userStatus}</p>
        </div>
        <div>
          <b>Schools:</b> <p>{user.schools}</p>
        </div>
        <div>
          <b>Verified students:</b>{" "}
          <ul>{formatVerifiedStudents(user.verifiedStudents)}</ul>
        </div>
        <hr></hr>

        <div className={styles.transactions}>
          {isLoadingTransactions && (
            <div className={styles.searchesErrorContainer}>
              <PuffLoader
                css={override}
                size={100}
                color={"#484848"}
                radius={"0.5em"}
                loading={true}
              />
              <p>Loading transactions...</p>
            </div>
          )}

          {!isLoadingTransactions && transactionsError && (
            <div className={styles.searchesErrorContainer}>
              <p>{transactionsError}</p>
            </div>
          )}

          {!isLoadingTransactions && !transactionsError && formatTransactions()}
        </div>
        <hr></hr>
        <h3>Past Searches</h3>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => resetSearches()}
            isRedirect={false}
            buttonText='Reset past searches number to 0'
            isPrimary={true}
          />
        </div>

        <div className={styles.pastSearchesContainer}>
          {isLoadingSearches && (
            <div className={styles.searchesErrorContainer}>
              <PuffLoader
                css={override}
                size={100}
                color={"#484848"}
                radius={"0.5em"}
                loading={true}
              />
              <p>Loading previous search images...</p>
            </div>
          )}

          {!isLoadingSearches && searchesError && (
            <div className={styles.searchesErrorContainer}>
              <p>{searchesError}</p>
            </div>
          )}
          {!searchesError &&
            !isLoadingSearches &&
            !pastSearchImageKeysAndUrls.length && (
              <div className={styles.searchesErrorContainer}>
                <p>No searches made</p>
              </div>
            )}

          {!searchesError &&
            !isLoadingSearches &&
            pastSearchImageKeysAndUrls.length > 0 && (
              <>
                {pastSearchImageKeysAndUrls.map((keyAndUrl) => {
                  return (
                    <div className={styles.imageCard}>
                      <PastSearchImageCard
                        keyAndUrl={keyAndUrl}
                        height={window.innerWidth > 600 ? 400 : 200}
                      />
                    </div>
                  );
                })}
              </>
            )}
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
