import React from "react";

import styles from "./UploadSelector.module.css";

import ImageSearch from "@material-ui/icons/ImageSearch";
import PrevSearch from "@material-ui/icons/AccessTime";

const UploadSelector = ({ setViewState }) => {
  return (
    <div className={styles.uploaderWrapper}>
      <div className={styles.heading}>
        <h2>Upload a photo of the student you are searching for</h2>
      </div>
      <div className={styles.uploadSelectorContainer}>
        <div className={styles.optionWrapper}>
          <div
            className={styles.uploadOption}
            onClick={() => setViewState("upload")}
          >
            <h5>Upload a New Photo</h5>
            <ImageSearch className={styles.optionsIcon}></ImageSearch>
          </div>
          <div
            className={styles.uploadOption}
            onClick={() => setViewState("past")}
          >
            <h5>Use a Previous Search</h5>
            <PrevSearch className={styles.optionsIcon}></PrevSearch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadSelector;
