import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./Login.module.css";

import {
  setUserAuthentication,
  setEmail,
  setUserSchools,
  selectIsAuthenticated,
  selectHasSubmittedLogin,
  selectLoginErrorMessage,
  selectEmail,
  selectIsLoggingIn,
  setIsLoggingIn,
  setLoginSubmitted,
  setLoginErrorMessage,
  setUsername,
  setFullname,
} from "../../app/reducers/UserSlice";

import { Auth } from "aws-amplify";

import InputField from "../../components/inputField/InputField";
import Button from "../../components/button/Button";

const TemporaryPasswordLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const submitted = useSelector(selectHasSubmittedLogin);
  const loginErrorMsg = useSelector(selectLoginErrorMessage);
  const email = useSelector(selectEmail);
  const isLoggingIn = useSelector(selectIsLoggingIn);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tempPassword, setTempPassword] = useState("");

  const handleSubmit = async () => {
    dispatch(setIsLoggingIn(true));
    dispatch(setLoginSubmitted(true));
    dispatch(setLoginErrorMessage(""));

    try {
      const userObj = await Auth.signIn(email, tempPassword);

      if (userObj.challengeName === "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(userObj, password);

        const userSession = await Auth.currentSession();

        dispatch(setUserAuthentication(true));
        dispatch(
          setUsername(userSession.getIdToken().payload["cognito:username"])
        );
        dispatch(setEmail(email));
        dispatch(
          setUserSchools(
            userSession.getIdToken().payload["custom:School1"].split(",")
          )
        );
        dispatch(
          setFullname(userSession.getIdToken().payload["custom:fullname"])
        );
        dispatch(setIsLoggingIn(false));
        history.push("/");
      } else {
        dispatch(setIsLoggingIn(false));
        dispatch(
          setLoginErrorMessage("Something went wrong. Please try again.")
        );
      }
    } catch (error) {
      dispatch(setIsLoggingIn(false));
      dispatch(setLoginErrorMessage(error.message));
    }
  };

  const validateForm = () => {
    return (
      email.length > 0 &&
      password.length > 0 &&
      confirmPassword.length > 0 &&
      tempPassword.length > 0 &&
      password === confirmPassword
    );
  };

  const passwordErrorMessage = () => {
    if (password !== "") {
      const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
      if (!regex.test(password)) {
        return "Required: 6 characters, one uppercase and lowercase letter and a number.";
      }
    }
    return "";
  };

  const confirmPasswordErrorMessage = () => {
    if (password !== "" && confirmPassword !== password) {
      return "Passwords do not match.";
    }
    return "";
  };

  return (
    <div className={styles.loginContainer}>
      <div>
        <div className={styles.headerContainer}>
          <h2 className={styles.headingFont}>Login</h2>
        </div>
        <InputField
          name='email'
          type='email'
          labelText='Email Address / Username'
          value={email}
          handleChange={(event) => dispatch(setEmail(event.target.value))}
          showError={submitted && !email}
          errorMessage='Email Address is required'
        />

        <InputField
          name='password'
          type='password'
          labelText='Temporary Password'
          value={tempPassword}
          handleChange={(event) => setTempPassword(event.target.value)}
          showError={submitted && !tempPassword}
          errorMessage='Password is required'
          isPasswordField={true}
        />

        <InputField
          name='password'
          type='password'
          labelText='New Password'
          value={password}
          handleChange={(event) => setPassword(event.target.value)}
          showError={passwordErrorMessage().length > 0}
          errorMessage={passwordErrorMessage()}
          isPasswordField={true}
        />
        <InputField
          name='confirmPassword'
          type='password'
          labelText='Confirm New Password'
          value={confirmPassword}
          handleChange={(event) => setConfirmPassword(event.target.value)}
          showError={confirmPasswordErrorMessage().length > 0}
          errorMessage={confirmPasswordErrorMessage()}
          isPasswordField={true}
        />

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => handleSubmit()}
            isRedirect={false}
            disabled={!validateForm()}
            buttonText='Login'
            isPrimary={true}
          />

          <div className={styles.loaderContainer}>
            {isLoggingIn && (
              <img
                alt='loading'
                className={styles.loader}
                src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
              />
            )}
            {!isAuthenticated && submitted && !isLoggingIn && (
              <img alt='cross' src='x-sym.jpg' className={styles.ximg} />
            )}

            <div className={styles.loginErrorMessage}>
              {!isLoggingIn && loginErrorMsg}
            </div>
            {!isLoggingIn && loginErrorMsg === "User is not confirmed." && (
              <Button
                buttonText='Resend or Confirm Code?'
                isRedirect={true}
                path='/confirm_registration'
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemporaryPasswordLogin;
